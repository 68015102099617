import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Checkbox, FormControlLabel } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "450px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function PrintSlipModal({
  setOpenModal,
  openModal,
  data,
  addressdata,
  saleProducts,
  tqty,
}) {

  const [verticalPrint, setVerticalPrint] = useState(false);

  useEffect(() => {
    // console.log("Chal gya")
    setVerticalPrint(true);
    setTimeout(() => {
      setVerticalPrint(false);
    }, 0);
  }, []);

  const handleToggle = () => {
    setVerticalPrint(prev => !prev);
  };

  const handleClose = () => {
    setOpenModal(false);
  };



  const invoiceRef = useRef(null);
  const invoiceRefVertical = useRef(null);
  const handlePrint = () => {


    const printWindow = window.open("", "", "height=600,width=1200");
    printWindow.document.open();
    printWindow.document.write(`
       <html>
        <head>
          <style>
            @media print {
            #invoice-POS {
              padding: 2mm;
              margin: 0 auto;
              width: 70mm;
              color: black;
              background: #fff;
              #top,
              #mid,
              #bot {
                border-bottom: 1px solid #000;
              }
                
              #bot {
                min-height: 50px;
              }

              .info {
                display: block;
                font-size:0.9rem;
                margin-left: 0;
                margin-top: 5px;
              }
              .title {
                float: right;
              }
              .title p {
                text-align: right;
              }
              table {
                width: 100%;
                border-collapse: collapse;
                font-weight:bold;
              }

              .tabletitle {
                font-size: 10px;
                font-weight:bold;
                background: #eee;
                padding-top: 10px;
              }
              .service {
                padding-top:5px;
                margin-top:5px  }
              .item {
                width: 5mm;
              }
              .itemtext {
                font-size: 0.8rem;
              }

              #legalcopy {
                margin-top: 5mm;
              }

              #legal {
                font-size:0.75rem
              }
            }

                .notetext{
              font-size: 16px !important;
              }


            #estDiv{
            margin-top:-22px
            }

            #billaddress{
            margin-top:-10px;
             font-size:0.95rem;
            font-weight:bold
          
            }

           

            .pricediv{
            margin-top:-25px
            }

            #tableitem-sale-p-name{
              height: fit-content;
              white-space: normal;
            word-wrap: break-word;
            line-height: 15px;
            }

  .fontLabelSize {
    font-size:0.7rem !important
    }
    


            }
            @page {
              size: auto;
              margin: 10mm;
            }
          </style>
        </head>
        <body onload="window.print(); window.close();">
        
          ${invoiceRef.current.innerHTML}
         
        </body>
      </html>

    `);
    printWindow.document.close();
  };

  const handlePrintA4 = (isA4 = false) => {
    const printWindow = window.open("", "", "height=600,width=1200");
    printWindow.document.open();
    const content = `
     <html>
        <head>
          <style>
            @media print {
              #invoice-POS {
                padding: 2mm;
                margin: 0 auto;
                width: ${isA4 ? "80mm" : "70mm"} !important; 
                color: black;
                background: #fff;
                text-align: center;
         
              }
              #a4size {
                max-width: ${isA4 ? "80mm" : "none"};
                  position: absolute;
                  top: 0%;
                  left: 50%;
                  transform: translate(-50%, 5%);
              }
              #top, #mid, #bot {
                border-bottom: 1px solid #000;
              }
              #bot {
                min-height: 50px;
              }
              .info {
                display: block;
                font-size: 0.9rem;
                margin-left: 0;
                margin-top: 5px;
              }
              .title {
                float: right;
              }
              .title p {
                text-align: right;
              }
              table {
                width: 100%;
                border-collapse: collapse;
                font-weight: bold;
              }
              .tabletitle {
                font-size: 10px;
                font-weight: bold;
                background: #eee;
                padding-top: 10px;
              }
              .service {
                padding-top: 5px;
                margin-top: 5px;
              }
              .item {
                width: 5mm;
              }
              .itemtext {
                font-size: 0.8rem;
              }

              .notetext{
              font-size: 16px !important;
              }


              #legalcopy {
                margin-top: 5mm;
              }
              #legal {
                font-size: 0.75rem;
              }
              #estDiv {
                margin-top: -22px;
              }
              #billaddress {
                margin-top: -10px;
                font-size:0.95rem;
               font-weight:bold
               
              }
          
              .pricediv {
                margin-top: -25px;
              }
              #tableitem-sale-p-name {
                height: fit-content;
                white-space: normal;
                word-wrap: break-word;
                line-height: 15px;
              }
              .fontLabelSize {
                font-size: 0.7rem !important;
              }
            }
            @page {
              size: auto;
              margin: 10mm;
            }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${invoiceRef.current.innerHTML}
        </body>
      </html>
    `;
    printWindow.document.write(content);
    printWindow.document.close();
  };

  const handlePrintVertical = () => {
    const printWindow = window.open("", "", "height=600,width=1200");
    printWindow.document.open();
    const content = `
    <html>
       <head>
         <style>
           @media print {
             #invoice-Vertical {
               padding: 2mm;
               margin: 0 auto;
               width: "70mm"; 
               color: black;
               background: #fff;
               text-align: left;
        
             }
               .vertical-product .product-name{
                font-size: 0.8rem;
               }
             #a4size {
               max-width: "80mm";
                 position: absolute;
                 top: 0%;
                 left: 50%;
                 transform: translate(-50%, 5%);
             }
             #top, #mid, #bot {
               border-bottom: 1px solid #000;
             }
             #bot {
               min-height: 50px;
             }
             .info {
               display: block;
               font-size: 0.9rem;
               margin-left: 0;
               margin-top: 5px;
             }
             .title {
               float: right;
             }
             .title p {
               text-align: right;
             }
             table {
               width: 100%;
               border-collapse: collapse;
               font-weight: bold;
             }
             .tabletitle {
               font-size: 10px;
               font-weight: bold;
               background: #eee;
               padding-top: 10px;
             }
             .service {
               padding-top: 5px;
               margin-top: 5px;
             }
             .item {
               width: 5mm;
             }
             .itemtext {
               font-size: 0.8rem;
             }

             .notetext{
             font-size: 16px !important;
             }


             #legalcopy {
               margin-top: 5mm;
             }
             #legal {
               font-size: 0.75rem;
             }
             #estDiv {
               margin-top: -22px;
             }
             #billaddress {
               margin-top: -10px;
               font-size:0.95rem;
              font-weight:bold
              
             }
         
             .pricediv {
               margin-top: -25px;
             }
             #tableitem-sale-p-name {
               height: fit-content;
               white-space: normal;
               word-wrap: break-word;
               line-height: 15px;
             }
             .fontLabelSize {
               font-size: 0.7rem !important;
             }
           }
           @page {
             size: auto;
             margin: 10mm;
           }
         </style>
       </head>
       <body onload="window.print(); window.close();">
         ${invoiceRefVertical.current.innerHTML}
       </body>
     </html>
   `;

    printWindow.document.write(content);
    printWindow.document.close();
  }


  

  return (
    <>
 
      <Modal
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Slip
          </Typography> */}

          {verticalPrint ? (
            <>
              <div style={{ maxHeight: "75vh", overflowY: "scroll" }} ref={invoiceRefVertical}>
                <div id="invoice-Vertical" className="a4Print" >
                  <div id="a4size">
                    <center id="top">
                      <div className="info">
                        <h1 style={{ fontSize: "20px", fontFamily: "sans-serif" }}>
                          ESTIMATE
                        </h1>
                      </div>
                    </center>
                    <div style={{ backgroundColor: "black", height: "1px" }}></div>
                    <div id="mid">
                      <div className="info">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <h3
                              style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                            >
                              ESTIMATE No.:
                            </h3>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <h3
                              style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                            >
                              KTT-0000{data.id}
                            </h3>
                          </div>
                        </div>

                        <div
                          id="estDiv"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <h3
                              style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                            >
                              ESTIMATE Date:
                            </h3>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <h3
                              style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                            >
                              {data.sale_date}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="mid">
                      <div className="info">
                        <h3 style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                          To
                        </h3>
                        <p
                          id="billaddress"
                          style={{


                            marginTop: "-9px",
                            fontFamily: "sans-serif",
                          }}
                        >
                          {addressdata.customer_name}
                        </p>
                      </div>
                    </div>

                    <div id="bot">
                      {saleProducts.map((data, index) => (
                        <>
                          <div className="vertical-product" style={{ width: "100%", display: "flex", alignItems: "baseline", marginBottom: "0.3rem" }}>
                            <div style={{ width: '5%', fontSize: "11px" }}><b>{index + 1}</b></div>
                            <div style={{ width: '95%' }}>
                              <p
                                className="product-name"
                                style={{
                                  maxWidth: "300px",
                                  height: "fit-content",
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                  lineHeight: "15px",
                                  marginBottom: "0.2rem"
                                }}>
                                {data.part_number && data.part_number !== "-"
                                  ? data.part_number
                                  : ""}
                                <br />
                                <b>{data.product_name}</b>
                              </p>


                              <div style={{ display: "flex", justifyContent: "space-between", fontSize: "10.5px" }} >
                                <div>
                                  <p className="itemtext" style={{ display: "inline", width: "25%" }}> Qty : <b>{data.quantity}</b> </p>
                                </div>
                                <div>
                                  <p className="itemtext" style={{ display: "inline", width: "25%" }}> MRP : <b>₹{data.mrp && data.mrp.toFixed(0)}</b> </p>
                                </div>
                                <div>
                                  {/* <p className="itemtext" style={{ display: "inline", width: "25%" }}> Dis : <b>₹{data.discount_amount && data.discount_amount.toFixed(0)}</b> </p> */}
                                  <p className="itemtext" style={{ display: "inline", width: "25%" }}> Dis : <b>{data.mrp && data.discount_amount && !isNaN(data.discount_amount) && !isNaN(data.mrp)
                                    ? ((data.discount_amount / data.mrp) * 100).toFixed(2) + "%"
                                    : "0%"}</b> </p>
                                </div>
                                <div>
                                  <p className="itemtext" style={{ display: "inline", width: "25%" }}> Price : <b>₹{data.price && data.price.toFixed(0)}</b> </p>
                                </div>
                              </div>


                              <div style={{ display: "flex", gap: "0.6rem" }} >
                                <div>
                                  <div
                                    className="fontLabelSize"
                                    style={{ fontSize: "11px", fontFamily: "sans-serif" }}
                                  >
                                    <b>Amount :</b>
                                  </div>
                                </div>
                                <div style={{ textAlign: "right" }}>
                                  <div className="fontLabelSize" style={{ fontSize: "11.5px", fontFamily: "sans-serif" }} >
                                    <b>₹{(data.quantity * data.price).toFixed(0)}</b>
                                  </div>
                                </div>
                              </div>


                              {/* <div style={{ fontSize: "0.9rem" }}>
                                <p className="itemtext" style={{ display: "inline" }}> Qty : <b>{data.quantity}</b> </p>
                                <p className="itemtext" style={{ display: "inline" }}> MRP : <b>₹{data.mrp && data.mrp.toFixed(0)}</b> </p>
                                <p className="itemtext" style={{ display: "inline" }}> Dis : <b>₹{data.discount_amount && data.discount_amount.toFixed(0)}</b> </p>
                                <p className="itemtext" style={{ display: "inline" }}> Unit Price : <b>₹{data.price && data.price.toFixed(0)}</b> </p>
                                <p className="itemtext" style={{ display: "inline" }}> Amount : <b>₹{(data.quantity * data.price).toFixed(0)}</b> </p>

                              </div> */}


                            </div>
                          </div>
                        </>
                      ))}
                    </div>



                    <div id="bot">

                      <div
                        style={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <div>
                          <h5
                            className="fontLabelSize"
                            style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                          >
                            Total Qty:
                          </h5>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <h3
                            className="fontLabelSize"
                            style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                          >
                            {tqty}
                          </h3>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <div>
                          <h5
                            className="fontLabelSize"
                            style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                          >
                            Discount:
                          </h5>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <h3
                            className="fontLabelSize"
                            style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                          >
                            ₹
                            {data.discount_amount
                              ? data.discount_amount.toFixed(2)
                              : 0.0}
                          </h3>
                        </div>
                      </div>

                      <div
                        className="pricediv"
                        style={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <div>
                          <h5
                            className="fontLabelSize"
                            style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                          >
                            Shipping Charge:
                          </h5>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <h3
                            className="fontLabelSize"
                            style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                          >
                            ₹
                            {data.courier_charge
                              ? data.courier_charge.toFixed(2)
                              : 0.0}
                          </h3>
                        </div>
                      </div>

                      <div
                        className="pricediv"
                        style={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <div>
                          <h5
                            className="fontLabelSize"
                            style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                          >
                            Other Charges:
                          </h5>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <h3
                            className="fontLabelSize"
                            style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                          >
                            ₹
                            {data.other_charge ? data.other_charge.toFixed(2) : 0.0}
                          </h3>
                        </div>
                      </div>

                      <div style={{ backgroundColor: "black", height: "1px" }} ></div>
                      <div
                        className="pricediv"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <div>
                          <h5
                            style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                          >
                            Total:
                          </h5>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <h3
                            style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                          >
                            ₹
                            {data.total_amount
                              ? (
                                data.total_amount +
                                data.other_charge +
                                data.courier_charge -
                                data.discount_amount
                              ).toFixed(2)
                              : 0.0}
                          </h3>
                        </div>
                      </div>

                      <p style={{ fontSize: "14px" }} className="notetext">
                        {data.note ? (
                          <span>
                            Note: <b>{data.note}</b>
                          </span>
                        ) : null}
                      </p>
                      <p style={{ fontSize: "14px" }} className="notetext">
                        {data.shipping_method && data.shipping_method.shipping_method ? (
                          <span>
                            Shipping: <b>{data.shipping_method.shipping_method}</b>
                          </span>
                        ) : null}
                      </p>
                      <p style={{ fontWeight: "500", marginBottom: "0px" }}>
                        {data.is_skd === 1 ? (
                          "SKD"
                        ) : data.is_nkd === 1 ? (
                          "NKD"
                        ) : null}
                      </p>

                      <div id="legalcopy" style={{ marginTop: "2px" }}>
                        <strong
                          style={{ fontSize: "11px", fontFamily: "sans-serif" }}
                        >
                          Terms & Conditions
                        </strong>
                        <p
                          className="legal"
                          style={{ fontSize: "0.8rem", fontFamily: "sans-serif" }}
                        >
                          This is a computer generated receipt not a valid tax
                          document.
                          <br />
                          There is no guarantee/warranty.
                          <br />
                          No return only exchange.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div style={{ maxHeight: "75vh", overflowY: "scroll" }}>
                <div id="invoice-POS" className="a4Print" ref={invoiceRef}>
                  <div id="a4size">
                    <center id="top">
                      <div className="info">
                        <h1 style={{ fontSize: "20px", fontFamily: "sans-serif" }}>
                          ESTIMATE
                        </h1>
                      </div>
                    </center>
                    <div style={{ backgroundColor: "black", height: "1px" }}></div>
                    <div id="mid">
                      <div className="info">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <h3
                              style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                            >
                              ESTIMATE No.:
                            </h3>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <h3
                              style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                            >
                              KTT-0000{data.id}
                            </h3>
                          </div>
                        </div>

                        <div
                          id="estDiv"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <h3
                              style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                            >
                              ESTIMATE Date:
                            </h3>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <h3
                              style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                            >
                              {data.sale_date}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="mid">
                      <div className="info">
                        <h3 style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                          To
                        </h3>
                        <p
                          id="billaddress"
                          style={{


                            marginTop: "-9px",
                            fontFamily: "sans-serif",
                          }}
                        >
                          {addressdata.customer_name}
                        </p>
                      </div>
                    </div>

                    <div id="bot">
                      <div id="table">
                        <table>
                          <tr
                            className="tabletitle"
                            style={{
                              fontSize: "11px",
                              fontFamily: "sans-serif",
                              fontWeight: "bold",
                            }}
                          >
                            <td className="Hours">SN</td>
                            <td className="Hours">Name</td>
                            <td className="Rate">Qty</td>

                            <td className="Rate" style={{ minWidth: "50px" }}>
                              Unit Price
                            </td>
                            <td className="Rate" style={{ textAlign: "right" }}>
                              Amount
                            </td>
                          </tr>

                          {saleProducts.map((data, index) => (
                            <>
                              <tr
                                className="service"
                                style={{
                                  fontSize: "11px",
                                  fontFamily: "sans-serif",
                                }}
                              >
                                <td className="tableitem" style={{ width: '2px', verticalAlign: 'top' }}>{index + 1}</td>
                                <td
                                  className="tableitem"
                                  style={{
                                    maxWidth: "130px",
                                    height: "fit-content",
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    lineHeight: "15px",
                                  }}
                                  id="tableitem-sale-p-name"
                                >
                                  <p className="itemtext">
                                    {data.part_number && data.part_number !== "-"
                                      ? data.part_number
                                      : ""}
                                    <br />
                                    <b>{data.product_name}</b>
                                  </p>
                                </td>
                                <td className="tableitem">
                                  <p
                                    className="itemtext"
                                    style={{ textAlign: "center" }}
                                  >
                                    <b>{data.quantity}</b>
                                  </p>
                                </td>
                                <td className="tableitem">
                                  <p className="itemtext">
                                    <b>₹{data.price && data.price.toFixed(0)}</b>
                                  </p>
                                </td>

                                <td className="tableitem">
                                  <p
                                    className="itemtext"
                                    style={{ textAlign: "right" }}
                                  >
                                    <b>
                                      ₹{(data.quantity * data.price).toFixed(0)}
                                    </b>
                                  </p>
                                </td>
                              </tr>
                            </>
                          ))}

                          <tr
                            className="service"
                            style={{ fontSize: "11px", fontFamily: "sans-serif" }}
                          >
                            <td colSpan={2}>
                              <b>Total Qty</b>
                            </td>
                            <td
                              className="tableitem"
                              style={{ textAlign: "center" }}
                            >
                              <b>{tqty}</b>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <div>
                          <h5
                            className="fontLabelSize"
                            style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                          >
                            Discount:
                          </h5>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <h3
                            className="fontLabelSize"
                            style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                          >
                            ₹
                            {data.discount_amount
                              ? data.discount_amount.toFixed(2)
                              : 0.0}
                          </h3>
                        </div>
                      </div>

                      <div
                        className="pricediv"
                        style={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <div>
                          <h5
                            className="fontLabelSize"
                            style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                          >
                            Shipping Charge:
                          </h5>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <h3
                            className="fontLabelSize"
                            style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                          >
                            ₹
                            {data.courier_charge
                              ? data.courier_charge.toFixed(2)
                              : 0.0}
                          </h3>
                        </div>
                      </div>

                      <div
                        className="pricediv"
                        style={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <div>
                          <h5
                            className="fontLabelSize"
                            style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                          >
                            Other Charges:
                          </h5>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <h3
                            className="fontLabelSize"
                            style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                          >
                            ₹
                            {data.other_charge ? data.other_charge.toFixed(2) : 0.0}
                          </h3>
                        </div>
                      </div>

                      <div
                        style={{ backgroundColor: "black", height: "1px" }}
                      ></div>
                      <div
                        className="pricediv"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "5px",
                        }}
                      >
                        <div>
                          <h5
                            style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                          >
                            Total:
                          </h5>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <h3
                            style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                          >
                            ₹
                            {data.total_amount
                              ? (
                                data.total_amount +
                                data.other_charge +
                                data.courier_charge -
                                data.discount_amount
                              ).toFixed(2)
                              : 0.0}
                          </h3>
                        </div>
                      </div>

                      <p style={{ fontSize: "14px" }} className="notetext">
                        {data.note ? (
                          <span>
                            Note: <b>{data.note}</b>
                          </span>
                        ) : null}
                      </p>
                      <p style={{ fontSize: "14px" }} className="notetext">
                        {data.shipping_method && data.shipping_method.shipping_method ? (
                          <span>
                            Shipping: <b>{data.shipping_method.shipping_method}</b>
                          </span>
                        ) : null}
                      </p>

                      <p style={{ fontWeight: "500", marginBottom: "0px", marginTop: "0px" }}>
                        {data.is_skd === 1 ? (
                          "SKD"
                        ) : data.is_nkd === 1 ? (
                          "NKD"
                        ) : null}
                      </p>

                      <div id="legalcopy" style={{ marginTop: "10px" }}>
                        <strong
                          style={{ fontSize: "11px", fontFamily: "sans-serif" }}
                        >
                          Terms & Conditions
                        </strong>
                        <p
                          className="legal"
                          style={{ fontSize: "0.8rem", fontFamily: "sans-serif" }}
                        >
                          This is a computer generated receipt not a valid tax
                          document.
                          <br />
                          There is no guarantee/warranty.
                          <br />
                          No return only exchange.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}


          <div
            style={{ maxHeight: "75vh", overflowY: "scroll", display: "none" }}
          >
            <div id="invoice-POSa4" className="a4Print" ref={invoiceRef}>
              <div id="a4size">
                <center id="top">
                  <div className="info">
                    <h1 style={{ fontSize: "20px", fontFamily: "sans-serif" }}>
                      ESTIMATE
                    </h1>
                  </div>
                </center>
                <div style={{ backgroundColor: "black", height: "1px" }}></div>
                <div id="mid">
                  <div className="info">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h3
                          style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                        >
                          ESTIMATE No.:
                        </h3>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <h3
                          style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                        >
                          KTT-0000{data.id}
                        </h3>
                      </div>
                    </div>

                    <div
                      id="estDiv"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h3
                          style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                        >
                          ESTIMATE Date:
                        </h3>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <h3
                          style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                        >
                          {data.sale_date}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="mid">
                  <div className="info">
                    <h3 style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                      To
                    </h3>
                    <p
                      id="billaddress"
                      style={{

                        marginTop: "-9px",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {addressdata.customer_name}
                    </p>
                  </div>
                </div>

                <div id="bot">
                  <div id="table">
                    <table>
                      <tr
                        className="tabletitle"
                        style={{
                          fontSize: "11px",
                          fontFamily: "sans-serif",
                          fontWeight: "bold",
                        }}
                      >
                        <td className="Hours">SN</td>
                        <td className="Hours">Name</td>
                        <td className="Rate">Qty</td>

                        <td className="Rate" style={{ minWidth: "60px" }}>
                          Unit Price
                        </td>
                        <td className="Rate" style={{ textAlign: "right" }}>
                          Amount
                        </td>
                      </tr>

                      {saleProducts.map((data, index) => (
                        <>
                          <tr
                            className="service"
                            style={{
                              fontSize: "11px",
                              fontFamily: "sans-serif",
                            }}
                          >
                            <td className="tableitem" style={{ width: '2px', verticalAlign: 'top' }}>{index + 1}</td>

                            <td
                              className="tableitem"
                              style={{
                                maxWidth: "130px",
                                height: "fit-content",
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                lineHeight: "15px",
                              }}
                              id="tableitem-sale-p-name"
                            >
                              <p className="itemtext">
                                {data.part_number && data.part_number !== "-"
                                  ? data.part_number
                                  : ""}
                                <br />
                                <b>{data.product_name}</b>
                              </p>
                            </td>
                            <td className="tableitem">
                              <p
                                className="itemtext"
                                style={{ textAlign: "center" }}
                              >
                                <b>{data.quantity}</b>
                              </p>
                            </td>
                            <td className="tableitem">
                              <p className="itemtext">
                                <b>₹{data.price && data.price.toFixed(0)}</b>
                              </p>
                            </td>

                            <td className="tableitem">
                              <p
                                className="itemtext"
                                style={{ textAlign: "right" }}
                              >
                                <b>
                                  ₹{(data.quantity * data.price).toFixed(0)}
                                </b>
                              </p>
                            </td>
                          </tr>
                        </>
                      ))}

                      <tr
                        className="service"
                        style={{ fontSize: "11px", fontFamily: "sans-serif" }}
                      >
                        <td colSpan={2}>
                          <b>Total Qty</b>
                        </td>
                        <td
                          className="tableitem"
                          style={{ textAlign: "center" }}
                        >
                          <b>{tqty}</b>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <h5
                        className="fontLabelSize"
                        style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                      >
                        Discount:
                      </h5>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <h3
                        className="fontLabelSize"
                        style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                      >
                        ₹
                        {data.discount_amount
                          ? data.discount_amount.toFixed(2)
                          : 0.0}
                      </h3>
                    </div>
                  </div>

                  <div
                    className="pricediv"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <h5
                        className="fontLabelSize"
                        style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                      >
                        Shipping Charge:
                      </h5>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <h3
                        className="fontLabelSize"
                        style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                      >
                        ₹
                        {data.courier_charge
                          ? data.courier_charge.toFixed(2)
                          : 0.0}
                      </h3>
                    </div>
                  </div>

                  <div
                    className="pricediv"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <h5
                        className="fontLabelSize"
                        style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                      >
                        Other Charges:
                      </h5>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <h3
                        className="fontLabelSize"
                        style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                      >
                        ₹
                        {data.other_charge ? data.other_charge.toFixed(2) : 0.0}
                      </h3>
                    </div>
                  </div>

                  <div
                    style={{ backgroundColor: "black", height: "1px" }}
                  ></div>
                  <div
                    className="pricediv"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "5px",
                    }}
                  >
                    <div>
                      <h5
                        style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}
                      >
                        Total:
                      </h5>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <h3
                        style={{ fontSize: "13px", fontFamily: "sans-serif" }}
                      >
                        ₹
                        {data.total_amount
                          ? (
                            data.total_amount +
                            data.other_charge +
                            data.courier_charge -
                            data.discount_amount
                          ).toFixed(2)
                          : 0.0}
                      </h3>
                    </div>
                  </div>

                  <p style={{ fontSize: "14px" }} className="notetext">
                    {data.note ? (
                      <>
                        Note: <b>{data.note}</b>
                      </>
                    ) : null}
                  </p>

                  <p style={{ fontWeight: "500", marginBottom: "0px" }}>
                    {data.is_skd === 1 ? (
                      "SKD"
                    ) : data.is_nkd === 1 ? (
                      "NKD"
                    ) : null}
                  </p>

                  <div id="legalcopy" style={{ marginTop: "10px" }}>
                    <strong
                      style={{ fontSize: "11px", fontFamily: "sans-serif" }}
                    >
                      Terms & Conditions
                    </strong>
                    <p
                      className="legal"
                      style={{ fontSize: "0.8rem", fontFamily: "sans-serif" }}
                    >
                      This is a computer generated receipt not a valid tax
                      document.
                      <br />
                      There is no guarantee/warranty.
                      <br />
                      No return only exchange.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={verticalPrint}
                  onChange={handleToggle}
                  color="primary"
                />
              }
              label="Vipin Sir Print"
            />
          </div>

          {verticalPrint ? (
            <Button
              style={{ marginTop: "8px", marginLeft: "5px", float: "right" }}
              onClick={handlePrintVertical}
              variant="outlined"
              size="small"
            >
              Vertical
            </Button>

          ) : (
            <>
              <Button
                style={{ marginTop: "8px", marginLeft: "5px", float: "right" }}
                onClick={handlePrint}
                variant="outlined"
                size="small"
              >
                Thermal Print
              </Button>

              <Button
                color="success"
                style={{ marginTop: "8px", marginLeft: "5px", float: "right" }}
                onClick={handlePrintA4}
                variant="outlined"
                size="small"
              >
                A4 Print
              </Button>
            </>
          )}


          <Button
            style={{ marginTop: "8px", float: "right" }}
            variant="outlined"
            size="small"
            color="error"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

    </>
  );
}

export default PrintSlipModal;
