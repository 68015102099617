import {
  Box,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { apiKey, fetch_customer } from "../../../Api";
import axios from "axios";
import { Pagination, Icon } from "semantic-ui-react";
import CreateCustomer from "./CreateCustomer";

import toast, { Toaster } from "react-hot-toast";

import DeleteCustomer from "./DeleteCustomer";
import ViewCustomer from "./ViewCustomer";
import EditCustomer from "./EditCustomer";
import FilterSelectionModal from "../../../Components/FilterModal/FilterSelectionModal";
import FilterTableColumns from "../../../Components/FilterModal/FilterTableColumns";
import { useScreenWidth } from "../../../useScreenWidth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1400,
  minWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AllClients() {
  const isWideScreen = useScreenWidth();
  const [allClients, setAllClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState([
    { value: 1, label: "A" },
    { value: 2, label: "B" },
    { value: 3, label: "C" },
  ]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const navigate = useNavigate();

  const fetchCustomers = (page = currentPage, query = searchquery) => {
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
      // selectedCategory : selectedCategory || null
    };

    setLoading(true);

    axios
      .get(fetch_customer, {
        params: {
          page: parseInt(page),
          per_page: parseInt(pageSize),
          search: query,
          // selectedCategory :selectedCategory || []
          selectedCategory: JSON.stringify(selectedCategory) || "[]",
        },
        headers,
      })
      .then((response) => {
        if (response.data.status === 1 && response.data.customers) {
          // console.log(response.data.customers);
          setAllClients(response.data.customers);
          setTotalPages(response.data.num_pages);
        } else {
          console.log(response?.data);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        console.error("Error fetching clients:", error);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCustomers();
  }, [currentPage, pageSize, searchquery, selectedCategory]);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openDetailModal, setOpenDetailModal] = useState(false);

  const handleOpenDetailModal = (customer) => {
    // console.log(customer);
    setSelectedCustomer(customer);
    setOpenDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setSelectedCustomer(null);
    setOpenDetailModal(false);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const [openAddcustomer, setAddCustomerOpen] = useState(false);
  const handleOpenAddcustomer = () => setAddCustomerOpen(true);
  const handleCloseAddcustomer = () => setAddCustomerOpen(false);

  // Delete Customer
  const [openDelete, setOpenDelete] = useState(false);

  const [pks, setPks] = useState([]);
  const handleDeleteCustomer = () => {
    const selectedPks = selectedRows.map((client) => client.id);

    setPks(selectedPks);

    if (selectedPks.length === 0) {
      toast.error("No Customers selected!");
      return;
    } else {
      setOpenDelete(true);
    }
  };

  // Edit Customer

  const [selectedEditCustomer, setSelectedEditCustomer] = useState();
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState(false);

  const handleEditCustomer = (customer) => {
    // console.log(customer);
    setSelectedEditCustomer(customer);
    setOpenEditCustomerModal(true);
  };

  // Pagination

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  // Selection

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Only include products that are deletable
      const deletableClients = allClients.filter(
        (client) => client.deletable !== 0
      );
      setSelectedRows(deletableClients);
    } else {
      // Clear selectedRows if unchecked
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (client) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.find((item) => item.id === client.id)) {
        // If already selected, remove it
        return prevSelected.filter((item) => item.id !== client.id);
      } else {
        // Otherwise, add the full client object
        return [...prevSelected, client];
      }
    });
  };

  return (
    <>
      <Toaster position="top-right" />

      <Modal
        open={openEditCustomerModal}
        onClose={() => setOpenEditCustomerModal(false)}
      >
        <Box sx={style}>
          <EditCustomer
            customerEditDetail={selectedEditCustomer}
            apiKey={apiKey}
            closeModal={() => setOpenEditCustomerModal(false)}
            fetchCustomers={fetchCustomers}
          />
        </Box>
      </Modal>

      <DeleteCustomer
        selectedPks={pks}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchCustomers}
      />

      <CreateCustomer
        opencustomer={openAddcustomer}
        handleOpencustomer={handleOpenAddcustomer}
        handleClosecustomer={handleCloseAddcustomer}
        fetchCustomers={fetchCustomers}
      />

      <ViewCustomer
        openViewCustomer={openDetailModal}
        handleCloseCustomer={handleCloseDetailModal}
        selectedCustomer={selectedCustomer}
        fetchCustomers={fetchCustomers}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit">Customers</Typography>
            <Typography sx={{ color: "text.primary" }}>
              All Customers
            </Typography>
          </Breadcrumbs>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              {isWideScreen ? (
                <>
                  <div
                    className="col-lg-6"
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {isWideScreen ? (
                      <FilterTableColumns tableId="all-customers" />
                    ) : null}

                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleOpenAddcustomer}
                    >
                      {" "}
                      Add Customer
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => navigate("/import-customers")}
                    >
                      Import Customers
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={handleDeleteCustomer}
                    >
                      Delete Customers
                    </Button>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table className="ui table celled all-customers" id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th style={{ width: "2px" }}>
                        <input
                          type="checkbox"
                          checked={
                            selectedRows.length ===
                              allClients.filter(
                                (client) => client.deletable !== 0
                              ).length &&
                            allClients.some((client) => client.deletable !== 0)
                          }
                          onChange={handleSelectAll}
                          disabled={allClients.every(
                            (client) => client.deletable === 0
                          )}
                        />
                      </th>
                      <th>Id</th>
                      <th>Customer Name</th>
                      <th>
                        <FilterSelectionModal
                          title="Category"
                          options={category}
                          selectedOptions={selectedCategory}
                          onSelect={setSelectedCategory}
                        />
                      </th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>GST Number</th>
                      <th>Address</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allClients &&
                      allClients.map((data) => (
                        <tr key={data.id}>
                          <td style={{ width: "2px" }}>
                            <input
                              type="checkbox"
                              checked={selectedRows.some(
                                (item) => item.id === data.id
                              )}
                              onChange={() => handleRowSelect(data)}
                              disabled={data.deletable === 0}
                            />
                          </td>
                          <td>{data.id}</td>
                          <td>{data.customer_name}</td>
                          <td>{data.category_ref.category}</td>
                          <td>{data.email}</td>
                          <td>{data.phone}</td>
                          <td>{data.gst_number}</td>
                          <td>
                            {data.primary_shipping
                              ? `${data.primary_shipping.address || ""}, ${
                                  data.primary_shipping.city || ""
                                }, ${data.primary_shipping.state || ""}, ${
                                  data.primary_shipping.country || ""
                                }`.trim()
                              : ""}
                          </td>
                          <td>
                            <Button
                              color="primary"
                              variant="outlined"
                              size="small"
                              // onClick={() => handleOpenDetailModal(data)}
                              onClick={()=> { navigate(`/customer-detail/${data.id}`)}}
                            >
                              View
                            </Button>
                            <Button
                              color="error"
                              variant="outlined"
                              size="small"
                              onClick={() => handleEditCustomer(data)}
                              style={{ marginLeft: "5px" }}
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {allClients.length > 0 ? (
                  <>
                    {allClients &&
                      allClients.map((data) => (
                        <>
                          <div
                            className="product-card"
                            onClick={() => handleOpenDetailModal(data)}
                          >
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">#{data.id}</h5>
                              <h5 className="product-card-name">
                                {data.customer_name}
                              </h5>
                              <h5 className="warehouse-card-address">
                                {data.phone} - {data.email}
                              </h5>
                              <small>
                                {data.primary_shipping && (
                                  <div>
                                    {data.primary_shipping
                                      ? `${
                                          data.primary_shipping.address || ""
                                        }, ${
                                          data.primary_shipping.city || ""
                                        }, ${
                                          data.primary_shipping.state || ""
                                        }, ${
                                          data.primary_shipping.country || ""
                                        }`.trim()
                                      : ""}
                                  </div>
                                )}{" "}
                              </small>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "5px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllClients;
