import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import {
  fetch_sale,
  apiKey,
  fetch_sale_detail,
  fetch_customer,
  export_todays_sale,
  get_shipping_methods,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import PrintSlipModal from "./PrintSlipModal";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CancelSale from "../../Messages/CancelSale";
import CustomLoader from "../../Components/Loader";
import SaleTypeModal from "./SelectSaleTypeModal";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useScreenWidth } from "../../useScreenWidth";
import { Icon, Label, Pagination, Segment } from "semantic-ui-react";
import { format, parseISO } from "date-fns";
import toast, { Toaster } from "react-hot-toast";
import FilterDateRangeModal from "../../Components/FilterModal/FilterDateRangeModal";
import FilterSelectionModalByNumber from "../../Components/FilterModal/FilterSelectionModalByNumber";

function Sales() {
  const isWideScreen = useScreenWidth();
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);



  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [shippingMethods, setShippingMethods] = useState([]);
  const [selectedShippingMethods, setSelectedShippingMethods] = useState([]);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  // date Filter
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCustomers();
    fetchShippingMethods();
  }, []);

  const fetchCustomers = () => {
    const headers = {
      "API-Key": apiKey,
    };

    axios
      .get(fetch_customer, {
        params: {
          page: 1,
          per_page: 1000,

        },

        headers,
      })
      .then((response) => {
        const customer = response.data.customers.map((cus) => ({
          value: cus.id,
          label: cus.customer_name,
        }));

        setCustomers(customer);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchShippingMethods = () => {
    axios
      .get(get_shipping_methods, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          const shippings = response.data.data.map((ship) => ({
            value: ship.id,
            label: ship.shipping_method,
          }));
          setShippingMethods(shippings);
        } else {
          toast.error(response.data.message || "Shipping Methods not fetched!");
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Internal server error");
        console.error("Error while fetching the shipping methods:", error?.response);
      });
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
      // }, 5000); // 10sec in milliseconds
    }, 60000); // 1 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, [
    currentPage,
    pageSize,
    searchquery,
    selectedCustomers,
    selectedShippingMethods,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    pageSize,
    searchquery,
    filterModel,
    selectedCustomers,
    startDate,
    endDate,
  ]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
      selected_customers: selectedCustomers || null,
      selected_shipping_method_pks: selectedShippingMethods || null,
      filters: filterModel.items || [],
      starting_date: startDate || "",
      ending_date: endDate || "",
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };
    axios
      .post(fetch_sale, data, { headers })
      .then((response) => {
        // console.log(response.data.data);
        setSales(response.data.data);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleViewClick = (id) => {
    navigate(`/view-sale/${id}`);
  };
  const handleEditClick = (id) => {
    navigate(`/edit-sale/${id}`);
  };

  const [openModal, setOpenModal] = useState(false);
  const [openSaleType, setOpenSaleType] = useState(false);

  const [data, setData] = useState([]);
  const [addressdata, setAddressData] = useState([]);
  const [saleProducts, setSaleProducts] = useState([]);

  const [currentDate, setCurrentDate] = useState(new Date());

  const formatedTodayDate = format(currentDate.toDateString(), "yyyy-MM-dd");

  const exportSale = () => {
    axios
      .get(export_todays_sale, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const allItems = response.data.all_items;

        // Create a worksheet from the data
        const worksheet = XLSX.utils.json_to_sheet(allItems);

        // Create a workbook and add the worksheet to it
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          `Sale-${formatedTodayDate}`
        );

        // Generate a binary string representation of the workbook
        const wbout = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "binary",
        });

        // Convert the binary string to a Blob object
        const blob = new Blob([s2ab(wbout)], {
          type: "application/octet-stream",
        });

        // Create a link element and trigger a download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Sale-${formatedTodayDate}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Utility function to convert a binary string to an array buffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const [tqty, setTqty] = useState(0);
  const handlePrintSlip = (pk) => {
    axios
      .get(fetch_sale_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          sale_pk: parseInt(pk),
        },
      })
      .then((response) => {
        setData(response.data.data);
        setTqty(
          response.data.data.items.reduce((sum, item) => sum + item.quantity, 0)
        );
        setAddressData(response.data.data.billing_customer);
        setSaleProducts(response.data.data.items);
      })
      .catch((error) => {
        console.log(error);
      });

    setOpenModal(true);
  };

  const handleSaleType = () => {
    setOpenSaleType(true);
  };

  const [cancelpk, setCancelPk] = useState("");
  const handleCancelSale = (pk) => {
    setCancelPk(pk);
    setOpenDelete(true);
  };

  function formatIndianRupee(amount) {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    });
    return formatter.format(amount);
  }

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />

      <SaleTypeModal
        openSaleType={openSaleType}
        setOpenSaleType={setOpenSaleType}
      />

      <CancelSale
        cancelpk={cancelpk}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />
      {openModal && (
        <PrintSlipModal
          tqty={tqty}
          openModal={openModal}
          setOpenModal={setOpenModal}
          fetchData={fetchData}
          saleProducts={saleProducts}
          data={data}
          addressdata={addressdata}
        />

      )}

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Sales</Typography>
              <Typography sx={{ color: "text.primary" }}>All Sales</Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-8 col-lg-4">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-4 col-lg-8"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  color="success"
                  onClick={exportSale}
                >
                  Export Today's Sale
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleSaleType}
                >
                  Add New
                </Button>
              </div>
            </div>
          </div>

          {/* {isWideScreen ? (
                  <FilterTableColumns tableId="SalesTable" />
                ) : null} */}

          {isWideScreen ? (
            <div className="responsive-table1">
              <table className="ui table celled SalesTable" id="c_table">
                <thead className="table-head">
                  <tr>
                    <th style={{ width: "2px" }}>#</th>
                    <th>
                      <FilterDateRangeModal
                        title="Sale Date"
                        startDate={startDate}
                        endDate={endDate}
                        onDateChange={handleDateChange}
                      />
                    </th>
                    {/* <th>
                      <FilterSelectionModalByNumber
                        title="Sale Type"
                        options={types}
                        selectedOptions={selectedTypes}
                        onSelect={setSelectedTypes}
                      />
                    </th> */}
                    <th>
                      <FilterSelectionModalByNumber
                        title="Customers"
                        options={customers}
                        selectedOptions={selectedCustomers}
                        onSelect={setSelectedCustomers}
                      />
                    </th>
                    <th>Created By</th>
                    <th>
                      <FilterSelectionModalByNumber
                        title="Shipping"
                        options={shippingMethods}
                        selectedOptions={selectedShippingMethods}
                        onSelect={setSelectedShippingMethods}
                      />
                    </th>
                    <th style={{ textAlign: "center" }}>Total Products</th>
                    <th style={{ textAlign: "right" }}>Amount</th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sales.length > 0 ? (
                    <>
                      {sales &&
                        sales.map((data, index) => (
                          <>
                            <tr>
                              <td
                                style={{
                                  width: "20px",
                                  backgroundColor:
                                    data.is_cancelled == 1
                                      ? "#ffe8e6"
                                      : "transparent",
                                }}
                              >
                                {data.id}
                              </td>
                              <td
                                style={{
                                  width: "160px",
                                  backgroundColor:
                                    data.is_cancelled == 1
                                      ? "#ffe8e6"
                                      : "transparent",
                                }}
                              >
                                <b>
                                  {format(
                                    parseISO(data.sale_date),
                                    "dd MMMM, yyyy"
                                  )}
                                </b>
                              </td>
                              {/* <td
                                style={{width:'150px',
                                  backgroundColor:
                                    data.is_cancelled == 1
                                      ? "#ffe8e6"
                                      : "transparent",
                                }}
                              >
                                {data.type_of_sale == 2
                                  ? "Party Sale"
                                  : "Cash Sale"}
                              </td> */}
                              <td
                                style={{
                                  backgroundColor:
                                    data.is_cancelled == 1
                                      ? "#ffe8e6"
                                      : "transparent",
                                }}
                              >
                                <b className="d-flex align-items-center justify-content-between">

                                  {data.billing_customer?.customer_name ||
                                    "N/A"}

                                  {["Karan", "Tech", "Vipin"].some((name) =>
                                    userDetails.name?.includes(name)
                                  ) ? (
                                    data.is_skd === 1 ? (
                                      <span className="badge bg-warning ms-2">SKD</span>
                                    ) : data.is_nkd === 1 ? (
                                      <span className="badge bg-success ms-2">NKD</span>
                                    ) : null
                                  ) : null}
                                </b>
                              </td>


                              <td
                                style={{
                                  backgroundColor:
                                    data.is_cancelled == 1
                                      ? "#ffe8e6"
                                      : "transparent",
                                }}
                              >
                                {data.created_by}
                              </td>
                              <td>
                                {data?.shipping_method?.shipping_method || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor:
                                    data.is_cancelled == 1
                                      ? "#ffe8e6"
                                      : "transparent",
                                }}
                              >
                                <span className="tbody_hint">
                                  No. of Products:{" "}
                                </span>
                                {data.total_products} QTY
                              </td>
                              <td
                                style={{
                                  color: "green",
                                  textAlign: "right",
                                  backgroundColor:
                                    data.is_cancelled == 1
                                      ? "#ffe8e6"
                                      : "transparent",
                                }}
                              >
                                <b>
                                  {formatIndianRupee(
                                    data.total_price -
                                    data.discount_amount +
                                    data.courier_charge +
                                    data.other_charge || "N/A"
                                  )}
                                </b>
                              </td>

                              <td
                                style={{
                                  maxWidth: "200px",
                                  backgroundColor:
                                    data.is_cancelled == 1
                                      ? "#ffe8e6"
                                      : "transparent",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="secondary"
                                  onClick={() => handleEditClick(data.id)}
                                  disabled={
                                    data.dispatched_status === 1 ||
                                    data.is_cancelled === 1
                                  }
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() => handleViewClick(data.id)}
                                  style={{ marginLeft: "5px" }}
                                >
                                  View
                                </Button>

                                {data.type_of_sale === 2 ? (
                                  <>
                                    {data.dispatched_status === 1 ||
                                      data.is_cancelled === 1 ? (
                                      <Button
                                        style={{ marginLeft: "5px" }}
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        onClick={() =>
                                          navigate(
                                            `/dispatch-details/${data.id}`
                                          )
                                        }
                                      >
                                        D. History
                                      </Button>
                                    ) : (
                                      <Button
                                        style={{ marginLeft: "5px" }}
                                        variant="outlined"
                                        color="info"
                                        size="small"
                                        onClick={() =>
                                          navigate(
                                            `/dispatch-sale-scan/${data.id}`
                                          )
                                        }
                                      >
                                        Dispatch
                                      </Button>
                                    )}
                                  </>
                                ) : null}

                                <Button
                                  disabled={data.is_cancelled === 1}
                                  variant="outlined"
                                  style={{ marginLeft: "5px" }}
                                  color="primary"
                                  size="small"
                                  onClick={() => handlePrintSlip(data.id)}
                                >
                                  Print
                                </Button>

                                {data.type_of_sale === 2 ? (
                                  <>
                                    <Button
                                      style={{ marginLeft: "5px" }}
                                      disabled={
                                        data.dispatched_status === 1 ||
                                        data.dispatched_status === 2 ||
                                        data.is_cancelled === 1
                                      }
                                      variant="outlined"
                                      color="error"
                                      size="small"
                                      onClick={() => handleCancelSale(data.id)}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : null}
                              </td>
                            </tr>
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={9}>
                          {!loading ? (
                            <div className="notfound_img_div">
                              <div className="notfound_img"></div>
                            </div>
                          ) : (
                            <div className="notfound_img_div">
                              <div className="loadingfound_img"></div>
                            </div>
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {sales.length > 0 ? (
                  <>
                    {sales &&
                      sales.map((data) => (
                        <>
                          <div
                            className="product-card"
                            style={{
                              position: "relative",
                              backgroundColor:
                                data.is_cancelled == 1
                                  ? "#ffe8e6"
                                  : "transparent",
                            }}
                          // onClick={() => handleViewClick(data.id)}
                          >
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">#{data.id}</h5>
                              <h5
                                className="sale-card-customer"
                                onClick={() => handleViewClick(data.id)}
                              >
                                {data.billing_customer?.customer_name || "N/A"}
                              </h5>
                              <h5 className="sale-card-date">
                                <span style={{ color: "green" }}>
                                  {formatIndianRupee(
                                    data.total_price -
                                    data.discount_amount +
                                    data.courier_charge +
                                    data.other_charge || "N/A"
                                  )}
                                </span>{" "}
                                | <span>{data.total_products} QTY</span> |{" "}
                                <span>{data.sale_date}</span>
                              </h5>

                              <Label
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "2px",
                                  fontSize: "10px",
                                }}
                                color={
                                  data.type_of_sale === 2 ? "orange" : "teal"
                                }
                                size="small"
                                tag
                              >
                                {data.type_of_sale == 2
                                  ? "Party Sale"
                                  : "Cash Sale"}
                              </Label>

                              {data.type_of_sale === 2 ? (
                                <>
                                  {data.dispatched_status === 1 ||
                                    data.is_cancelled === 1 ? null : (
                                    <Button
                                      className="mt-2"
                                      variant="outlined"
                                      color="info"
                                      size="small"
                                      onClick={() =>
                                        navigate(
                                          `/dispatch-sale-scan/${data.id}`
                                        )
                                      }
                                    >
                                      Dispatch
                                    </Button>
                                  )}
                                </>
                              ) : null}
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "2px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Sales;
