import React, { useEffect, useState } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import axios from "axios";
import { apiKey, edit_sale_summary, get_shipping_methods } from "../../Api";
import toast from "react-hot-toast";
import AddShippingMethodModal from "./AddSales/AddShippingMethodModal";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

function EditSaleSummaryPrices({
  sale_pk,
  fetchSales,
  totalamount,
  discountedprice,
  shipping,
  othercharge,
  onClose,
  selectedNkdSkd,
  shippingMethodPk
}) {
  const formatIndianRupee = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  const [formData, setFormData] = useState({
    discountedprice,
    shipping,
    othercharge,
  });
  // NKD or SKD
  const [EditedSelectedNkdSkd, setEditedSelectedNkdSkd] = useState(selectedNkdSkd);

  // Calculate Total Amount Dynamically
  const calculatedTotalAmount =
    totalamount -
    parseFloat(formData.discountedprice || 0) +
    parseFloat(formData.shipping || 0) +
    parseFloat(formData.othercharge || 0);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };




  const [shippingMethods, setShippingMethods] = useState([]);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState(shippingMethodPk || null);
  const [openShippingMethod, setOpenShippingMethod] = useState(false);
  const fetchShippingMethods = () => {
    axios
      .get(get_shipping_methods, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setShippingMethods(response.data.data);
        } else {
          toast.error(response.data.message || "Shipping Methods not fetched!");
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Internal server error");
        console.error("Error while fetching the shipping methods:", error?.response);
      });
  };

  // Handle new shipping method addition
  const handleNewShippingMethod = (newMethod) => {
    setShippingMethods((prev) => [...prev, newMethod]);
    setSelectedShippingMethod(newMethod.id);
  };


  useEffect(() => {
    fetchShippingMethods();
  }, []);


  const handleSave = () => {
    const editedSalePriceSummary = {
      sale_pk: parseInt(sale_pk),
      discount_amount: parseFloat(formData.discountedprice) || 0,
      shipping_charge: parseFloat(formData.shipping) || 0,
      other_charge: parseFloat(formData.othercharge) || 0,
      shipping_method_pk: parseInt(selectedShippingMethod) || 0,
      

      is_skd: EditedSelectedNkdSkd === "SKD" ? 1 : 0,
      is_nkd: EditedSelectedNkdSkd === "NKD" ? 1 : 0,
    };

    axios
      .post(edit_sale_summary, editedSalePriceSummary, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("Sale Summary Updated Successfully!");
          fetchSales();
          onClose();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data?.message || "Something went wrong!");
      });
  };

  return (
    <>
      {openShippingMethod && (
        <AddShippingMethodModal
          open={openShippingMethod}
          onClose={() => setOpenShippingMethod(false)}
          onAdd={handleNewShippingMethod}
        />
      )}
      <Dialog open={true} fullWidth maxWidth="sm" onClose={onClose}>
        <DialogTitle>
          <b>Edit Sale Summary</b>
        </DialogTitle>

        <DialogContent dividers>
          <Box>
            <div className="row mt-2">
              <div className="col-6 my-2">Sub-Total</div>
              <div className="col-6 my-2" style={{ textAlign: "right" }}>
                <h5 style={{ color: "black" }}>
                  {formatIndianRupee(totalamount)}
                </h5>
              </div>
              <div className="col-6 my-2">Total Discount (-)</div>
              <div className="col-6 my-2" style={{ textAlign: "right" }}>
                <h5 style={{ color: discountedprice > 0 ? "black" : "grey" }}>
                  <TextField
                    name="discountedprice"
                    value={formData.discountedprice}
                    onChange={handleChange}
                    type="number"
                    size="small"
                    style={{ maxWidth: "120px" }}
                  />
                </h5>
              </div>
              <div className="col-6 my-2">Shipping Charge</div>
              <div className="col-6 my-2" style={{ textAlign: "right" }}>
                <h5 style={{ color: shipping > 0 ? "black" : "grey" }}>
                  <TextField
                    name="shipping"
                    value={formData.shipping}
                    onChange={handleChange}
                    size="small"
                    type="number"
                    style={{ maxWidth: "120px" }}
                  />
                </h5>
              </div>
              <div className="col-6 my-2">Other Charges</div>
              <div className="col-6 my-2" style={{ textAlign: "right" }}>
                <h5 style={{ color: othercharge > 0 ? "black" : "grey" }}>
                  <TextField
                    name="othercharge"
                    value={formData.othercharge}
                    onChange={handleChange}
                    size="small"
                    type="number"
                    style={{ maxWidth: "120px" }}
                  />
                </h5>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6 my-2">
                <strong>Total Amount</strong>
              </div>
              <div className="col-6 my-2" style={{ textAlign: "right" }}>
                <strong style={{ color: "red", fontSize: "1.3rem" }}>
                  {formatIndianRupee(calculatedTotalAmount.toFixed(2))}
                </strong>
              </div>
            </div>


            <hr />
            <div className="card mt-2 ">
              <div className="d-flex gap-1 align-items-center">
                <FormControl size="small" fullWidth>
                  <InputLabel>Shipping Method</InputLabel>
                  <Select
                    value={selectedShippingMethod || ""}
                    label="Shipping Method"
                    onChange={(e) => setSelectedShippingMethod(e.target.value)}
                  >
                    <MenuItem value="">--Select Shipping Method--</MenuItem>
                    {shippingMethods.map((ship) => (
                      <MenuItem key={ship.id} value={ship.id}>
                        {ship.shipping_method}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button variant="outlined" color="primary" size="small" className="h-100" onClick={() => setOpenShippingMethod(true)} >
                  Add
                </Button>
              </div>
            </div>

            <hr />
            <div className="card p-3 mt-2">
              <div className="d-flex align-items-center gap-1">
                <Button
                  size="small"
                  variant={EditedSelectedNkdSkd === "SKD" ? "contained" : "outlined"}
                  style={{
                    height: "37px",
                    backgroundColor: EditedSelectedNkdSkd === "SKD" ? "#ff9800" : "transparent", // Orange when selected, transparent when not
                    color: EditedSelectedNkdSkd === "SKD" ? "#fff" : "#ff9800", // White text when selected, orange text when not
                    borderColor: "#ff9800", // Ensure the border is visible in outline mode
                  }}
                  className="w-50"
                  onClick={() => setEditedSelectedNkdSkd("SKD")}
                >
                  SKD
                </Button>
                <Button
                  size="small"
                  variant={EditedSelectedNkdSkd === "NKD" ? "contained" : "outlined"}
                  style={{
                    height: "37px",
                    backgroundColor: EditedSelectedNkdSkd === "NKD" ? "#28a745" : "transparent", // Green when selected, transparent when not
                    color: EditedSelectedNkdSkd === "NKD" ? "#fff" : "#28a745", // White text when selected, green text when not
                    borderColor: "#28a745", // Ensure the border is visible in outline mode
                  }}
                  className="w-50"
                  onClick={() => setEditedSelectedNkdSkd("NKD")}
                >
                  NKD
                </Button>
              </div>
            </div>

            

          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="error">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="outlined" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditSaleSummaryPrices;
