import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack, IoMdCloseCircleOutline } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";
import {
  apiKey,
  fetchSingleRequirement,
  requirement_details,
  stopRequirement,
  editQtyforRequirement,
  export_requirement,
  delete_requirement_item,
} from "../../Api";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useScreenWidth } from "../../useScreenWidth";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Input, Label } from "semantic-ui-react";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import CustomLoader from "../../Components/Loader";
import { FaImage } from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa6";
import AddProductInRequirementListModal from "./AddProductInRequirementListModal";
import { FaRegStopCircle } from "react-icons/fa";
import { GiStopwatch } from "react-icons/gi";
import * as XLSX from "xlsx";
import { FaTrashAlt } from "react-icons/fa";
import RemovePickupModal from "../../Messages/RemovePickupModal";

function RequirementDetails() {
  const { id } = useParams();
  const ws = useRef(null);
  const isWideScreen = useScreenWidth();
  const [singleRequirement, setSingleRequirementItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editedQty, setEditQty] = useState("");
  const [packedStatus, setPackedStatus] = useState("");
  const [searchquery, setSearchQuery] = useState("");
  const [editQtyPanel, setEditQtyPanel] = useState(false);

  const [details, setDetails] = useState({});

  const [openModal, setOpenModal] = useState(false);

  const [userDetails, setUserDetails] = useState("");

  const navigate = useNavigate();
  const [editQtyRule, setEditQtyRule] = useState(true);

  useEffect(() => {
    fetchData();
    const user = JSON.parse(localStorage.getItem("ktt_users"));

    setUserDetails(user || "");
    if (user) {
      if (user && user.role === "admin") {
        setEditQtyRule(false);
        // console.log(editQtyRule);
      } else if (
        (user && user.name.toLowerCase().includes("megha")) ||
        (user && user.name.toLowerCase().includes("gunjan"))
      ) {
        setEditQtyRule(false);
      } else {
        setEditQtyRule(true);
      }
      // Redirect if the user is "dispatch"
      if (user.role === "dispatch") {
        navigate(`/requirement-details-dispatch/${id}`);
      }

    }
  }, [id, navigate]);

  // console,log(user)

  const fetchData = () => {
    const data = {
      requirement_pk: parseInt(id),
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(fetchSingleRequirement, data, { headers })
      .then((response) => {
        setDetails(response.data.data);
      })
      .catch((error) => {
        navigate("/requirements-list");
        toast.error(error.response.data.message);

        setLoading(false);
      });
  };

  useEffect(() => {
    // Establish WebSocket connection with API-Key as a query parameter
    ws.current = new WebSocket(
      `${requirement_details}?requirement_pk=${id}&API-Key=${apiKey}`
    );

    ws.current.onopen = () => {
      // console.log("WebSocket connected");
      setLoading(false);
    };

    ws.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      // console.log(response);
      setPackedStatus(response.obj.packed_status);
      // setDetails(response.obj);
      setSingleRequirementItems(response.items);
    };

    ws.current.onerror = (error) => {
      console.error(error, "WebSocket error");
    };

    ws.current.onclose = () => {
      console.log("WebSocket disconnected");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [id, apiKey]);

  useEffect(() => {
    if (!loading) {
      fetchDataa();
    }
  }, [searchquery]);

  const fetchDataa = (query = searchquery) => {
    const bodyData = {
      requirement_pk: parseInt(id),
      search: query,
    };

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(bodyData));
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the data based on the search query
  const filteredData = singleRequirement.filter(
    (data) =>
      data.product_name.toLowerCase().includes(searchquery.toLowerCase()) ||
      data.product_sku.toLowerCase().includes(searchquery.toLowerCase()) ||
      data.part_number.toLowerCase().includes(searchquery.toLowerCase())
  );

  // To stop the Process of requirement List

  const handleStopClick = () => {
    setOpenModal(true);
  };

  const handleCloseStopModal = () => {
    setOpenModal(false);
  };

  const handleConfirmStop = () => {
    setOpenModal(false);

    const data = {
      user_name: userDetails.name || "",
      requirement_pk: parseInt(id),
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(stopRequirement, data, { headers })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("Requirement List Stopped Successfully !!");
          navigate("/requirements-list");
          // fetchData();
        }
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error.response?.data?.message || "Internal Server Error");
      });
  };

  const [editQtyPanelIndex, setEditQtyPanelIndex] = useState(null);
  const handleConfirmEditQty = async (id, packed_quantity) => {
    // Validate the quantity before submitting
    if (!editedQty || isNaN(editedQty) || editedQty <= 0) {
      toast.error("Please enter a valid quantity!");
      return;
    } else if (editedQty <= packed_quantity) {
      toast.error("Products already packed, you can't remove products!");
      setEditQtyPanelIndex(null);
      return;
    }

    const data = {
      item_pk: parseInt(id),
      quantity: parseInt(editedQty),
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(editQtyforRequirement, data, { headers })
      .then((response) => {
        if (response.data.status == 1) {
          toast.success("Quantity Updated Successfully !");
          setEditQtyPanel(false);
        }
        // fetchData();
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Internal Server Error");
      });

    setEditQtyPanelIndex(null);
  };

  const handleOpenEditPanel = (index) => {
    setEditQtyPanelIndex(index);
    setEditQty("");
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleAddProducts = (products) => {
    setSelectedProducts(products);
    // console.log("Selected Products: ", products);
  };

  const [exportLoading, setExportLoading] = useState(false);

  const handleExportRequirementDetails = async () => {
    console.log("handleExportRequirementDetails chala");
    setExportLoading(true);

    try {
      const response = await axios.get(export_requirement, {
        params: { requirement_pk: id },
        headers: { "API-Key": apiKey },
      });

      if (response.data.status === 1) {
        const requirementData = response.data.data;

        if (Array.isArray(requirementData)) {
          // Convert the array of objects to a worksheet
          const worksheet = XLSX.utils.json_to_sheet(requirementData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(
            workbook,
            worksheet,
            "Requirement Details"
          );

          // Save the file with a dynamic filename
          XLSX.writeFile(workbook, `Requirement_Details_${id}.xlsx`);
          toast.success("Exported Successfully!");
        } else {
          toast.error("Invalid data format received.");
        }
      } else {
        toast.error(response?.data?.message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Internal Server Error");
      console.error("Error while exporting requirement:", error);
    } finally {
      setExportLoading(false);
    }
  };

  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const [toRemoveSKU, setToRemoveSKU] = useState("");
  const [toRemovePK, setToRemovePk] = useState("");

  const handleRemoveProduct = (p_id, sku) => {
    setToRemovePk(p_id);
    setToRemoveSKU(sku);
    setOpenDeleteProduct(true);
  };

  const handleConfirmDelete = () => {
    axios
      .delete(delete_requirement_item, {
        headers: {
          "API-Key": apiKey,
        },
        data: {
          requirement_item_pk: parseInt(toRemovePK),
        },
      })
      .then(() => {
        toast.success(`Selected Product Deleted Successfully!`);
        setOpenDeleteProduct(false);
        fetchData();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handleCloseRemoveProduct = () => {
    setOpenDeleteProduct(false);
  };






  // handle Remove Picked Product  ( Bhawana )

  const [showRemoveProductPickedQty,setShowRemoveProductPickedQty] = useState(false);
  const [productPickedQty,setProductPickedQty] = useState(false);
  const [itemPk,setItemPk] = useState(false);

  const handleRemovePickedProduct = (item_id, picked) => {
    setShowRemoveProductPickedQty(true);
    setProductPickedQty(picked);
    setItemPk(item_id);
  };
  


  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />

      <RemovePickupModal
        setShowRemoveQty={setShowRemoveProductPickedQty}
        showRemoveQty={showRemoveProductPickedQty}
        item_pk={itemPk}
        picked_qty={productPickedQty}
      />


      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/requirements-list")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Requirement</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Requirement List
              </Typography>
              <Typography>#{id}</Typography>
            </Breadcrumbs>
          </div>

          <div className="row">
            <div className="col-md-9 mt-2 order-2 order-md-1">
              <div className="card p-3 mb-2">
                <div className="row">
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      placeholder="Search..."
                      style={{
                        backgroundColor: "white",
                        maxWidth: "400px",
                      }}
                      type="search"
                      value={searchquery}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div className="col-md-6 d-flex gap-2 flex-wrap align-items-center justify-content-end mt-2 mt-md-0">
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={() =>
                        navigate(`/pending-requirement-products/${id}`)
                      }
                    >
                      Pending Products
                    </Button>
                    <AddProductInRequirementListModal
                      // fetchMainData={fetchData}
                      onAddProducts={handleAddProducts}
                      requirementPk={id}
                      alreadyAddedProducts={singleRequirement}
                    />

                    {packedStatus && packedStatus !== 0 ? (
                      <>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => navigate(`/packed-details/${id}`)}
                        >
                          Packed Details
                        </Button>
                      </>
                    ) : null}

                    <Button
                      variant="outlined"
                      color="success"
                      onClick={handleExportRequirementDetails}
                      disabled={exportLoading}
                    >
                      {exportLoading ? "Exporting..." : "Export"}
                    </Button>
                  </div>
                </div>
              </div>

              {isWideScreen ? (
                <div className="responsive-table1">
                  <table className="ui table celled" id="c_table">
                    <thead className="table-head">
                      <tr>
                        <th>ID</th>
                        <th style={{ width: "110px" }}>Product SKU</th>
                        <th>Product Name</th>
                        <th>Part Number</th>
                        <th>Available Qty</th>
                        <th>Reqd Qty</th>
                        {["bhawana"].some((name) => userDetails.name?.includes(name)) ?
                          (
                            <th>Action</th>
                          ) : null}

                        <th className="text-center">Picked Qty</th>
                        <th className="text-center">Packed Qty</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData &&
                        filteredData.map((data, index) => (
                          <tr
                            key={data.id}
                            style={{
                              backgroundColor:
                                data.packed_quantity === data.picked_quantity && data.packed_quantity !== 0
                                  ? "#cce5ff"
                                  : data.quantity < data.picked_quantity
                                    ? "rgb(253 199 199)"
                                    : data.quantity == data.picked_quantity
                                      ? "#d1e7dd"
                                      : data.quantity > data.picked_quantity &&
                                        data.picked_quantity != 0
                                        ? "#fff3cd"
                                        : data.picked_quantity == 0
                                          ? "transparent"
                                          : "transparent",
                            }}
                          >
                            <td style={{ width: "50px" }}>#{data.id}</td>
                            <td>
                              {data.product_sku}
                              <button
                                onClick={() => {
                                  navigator.clipboard.writeText(data.product_sku);
                                  toast.success("Product SKU copied!", { autoClose: 1000 });
                                }}
                                style={{
                                  marginLeft: "5px",
                                  border: "none",
                                  background: "transparent",
                                  cursor: "pointer",
                                }}
                                title="Copy"
                              >
                                <FaRegCopy />
                              </button>
                            </td>

                            <td className="product_name_class" style={{ maxWidth: "100px" }}>
                              {data.product_name}
                              <button
                                onClick={() => {
                                  navigator.clipboard.writeText(data.product_name);
                                  toast.success("Product name copied!", { autoClose: 1000 });
                                }}
                                style={{
                                  marginLeft: "5px",
                                  border: "none",
                                  background: "transparent",
                                  cursor: "pointer",
                                }}
                                title="Copy"
                              >
                                <FaRegCopy />
                              </button>
                            </td>

                            <td style={{ maxWidth: "80px", textWrap: "wrap" }}>
                              {data.part_number}
                            </td>
                            <td style={{ width: "100px", textAlign: "center" }}>
                              {data.available_stock}{" "}
                              <span style={{ color: "grey", fontSize: "10px" }}>
                                QTY
                              </span>
                            </td>

                            <td style={{ width: "160px" }}>
                              <div style={{ display: "block" }}>
                                {editQtyPanelIndex === index ? (
                                  <>
                                    <Input
                                      placeholder="Qty"
                                      autoFocus
                                      disabled={editQtyRule}
                                      style={{
                                        width: "65px",
                                        height: "28px",
                                        marginLeft: "5px",
                                        padding: "0px",
                                      }}
                                      value={editedQty}
                                      onFocus={() => setEditQty("")} // Clear the value when input is focused
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (
                                          value === "" ||
                                          (value >= 0)
                                        ) {
                                          setEditQty(value);
                                        }
                                        // else {
                                        //   setEditQty(data.available_stock);
                                        //   toast.error(
                                        //     `Quantity Can't Exceed ${data.available_stock}`
                                        //   );
                                        // }
                                      }}
                                      type="number"
                                      min={0}
                                    />

                                    <IoMdCloseCircleOutline
                                      onClick={() => setEditQtyPanelIndex(null)}
                                      className="btn-iconn"
                                      style={{
                                        color: "red",
                                        borderColor: "red",
                                      }}
                                    />
                                    <IoCheckmarkDoneSharp
                                      onClick={() =>
                                        handleConfirmEditQty(
                                          data.id,
                                          data.packed_quantity
                                        )
                                      }
                                      className="btn-iconn"
                                      style={{
                                        color: "green",
                                        borderColor: "green",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      // disabled={editQtyRule}
                                      style={{
                                        width: "65px",
                                        height: "28px",
                                        marginLeft: "5px",
                                        padding: "0px",
                                      }}
                                      onClick={() =>
                                        editQtyRule == false
                                          ? handleOpenEditPanel(index)
                                          : null
                                      }
                                      value={data.quantity || 0}
                                    />
                                  </>
                                )}
                              </div>
                            </td>

                            {["bhawana"].some((name) => userDetails.name?.includes(name)) ?
                              (
                                <td>
                                   <Button
                                      disabled={
                                        data.quantity === data.picked_quantity
                                      }
                                      variant="outlined"
                                      size="small"
                                      onClick={() => navigate(`/requirement-pick-up/${data.id}`)}
                                    >
                                      Pick
                                    </Button>
                                    <Button
                                      disabled={
                                        data.packed_quantity ===
                                        data.picked_quantity
                                      }
                                      className="ms-2"
                                      variant="outlined"
                                      color="error"
                                      size="small"
                                      onClick={() =>
                                        handleRemovePickedProduct(
                                          data.id,
                                          data.picked_quantity
                                        )
                                      }
                                    >
                                      Remove
                                    </Button>

                                </td>
                              ) : null}

                            <td style={{ width: "100px", textAlign: "center" }}>
                              {data.picked_quantity}{" "}
                              <span style={{ color: "grey", fontSize: "10px" }}>
                                QTY
                              </span>
                            </td>
                            <td style={{ width: "100px", textAlign: "center" }}>
                              {data.packed_quantity}{" "}
                              <span style={{ color: "grey", fontSize: "10px" }}>
                                QTY
                              </span>
                            </td>

                            <td style={{ width: "20px" }}>
                              {data.packed_quantity > 0 ? (
                                <>
                                  <FaTrashAlt
                                    style={{
                                      color: "grey",
                                      cursor: "not-allowed",
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <FaTrashAlt
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() =>
                                      handleRemoveProduct(
                                        data.id,
                                        data.product_sku
                                      )
                                    }
                                  />
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <>
                  <div className="card p-2 responsive-mobile-table">
                    {singleRequirement.length > 0 ? (
                      <>
                        {singleRequirement &&
                          singleRequirement.map((requirement, index) => (
                            <>
                              <div
                                className="product-card"
                                style={{
                                  backgroundColor:
                                    requirement.quantity <
                                      requirement.picked_quantity
                                      ? "rgb(253 199 199)"
                                      : requirement.quantity ==
                                        requirement.picked_quantity
                                        ? "#d1e7dd"
                                        : requirement.quantity >
                                          requirement.picked_quantity &&
                                          requirement.picked_quantity != 0
                                          ? "#fff3cd"
                                          : requirement.picked_quantity == 0
                                            ? "transparent"
                                            : "transparent",
                                }}
                              >
                                <div>
                                  <FaImage className="product-img" />
                                </div>
                                <div className="py-1 px-2">
                                  <h5 className="product-card-sku">
                                    #{requirement.product_sku}
                                  </h5>
                                  <h5 className="product-card-name">
                                    {requirement.product_name}
                                  </h5>

                                  <h5
                                    className="product-card-mrp mt-2"
                                    style={{ color: "grey" }}
                                  >
                                    <b style={{ color: "black" }}>
                                      {requirement.part_number}
                                    </b>
                                  </h5>
                                  <div
                                    className="d-flex gap-2"
                                    style={{
                                      position: "absolute",
                                      right: "12px",
                                      marginTop: "-22px",
                                    }}
                                  >
                                    <div>
                                      {editQtyPanelIndex === index ? (
                                        <>
                                          <Input
                                            placeholder="Qty"
                                            autoFocus
                                            style={{
                                              width: "70px",
                                              height: "28px",
                                              marginLeft: "5px",
                                              padding: "0px",
                                            }}
                                            value={editedQty}
                                            onFocus={() => setEditQty("")} // Clear the value when input is focused
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              if (
                                                value === "" ||
                                                (value <=
                                                  requirement.available_stock_in_other &&
                                                  value >= 0)
                                              ) {
                                                setEditQty(value);
                                              } else {
                                                setEditQty(
                                                  requirement.available_stock_in_other
                                                );
                                                toast.error(
                                                  `Quantity Can't Exceed ${requirement.available_stock_in_other}`
                                                );
                                              }
                                            }}
                                            type="number"
                                            min={0}
                                          />
                                          <IoCheckmarkDoneSharp
                                            onClick={() =>
                                              handleConfirmEditQty(
                                                requirement.id,
                                                requirement.packed_quantity
                                              )
                                            }
                                            className="btn-iconn"
                                            style={{
                                              color: "green",
                                              borderColor: "green",
                                            }}
                                          />
                                          <IoMdCloseCircleOutline
                                            onClick={() =>
                                              setEditQtyPanelIndex(null)
                                            }
                                            className="btn-iconn"
                                            style={{
                                              color: "red",
                                              borderColor: "red",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <Input
                                            readOnly
                                            style={{
                                              width: "70px",
                                              height: "28px",
                                              marginLeft: "5px",
                                              padding: "0px",
                                            }}
                                            onClick={() =>
                                              handleOpenEditPanel(index)
                                            }
                                            value={requirement.quantity || 0}
                                          />
                                        </>
                                      )}
                                    </div>

                                    <div style={{ marginTop: "1.5px" }}>
                                      {requirement.packed_quantity > 0 ? (
                                        <>
                                          <FaTrashAlt
                                            style={{
                                              color: "grey",
                                              cursor: "not-allowed",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <FaTrashAlt
                                            style={{
                                              color: "red",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleRemoveProduct(
                                                requirement.id,
                                                requirement.product_sku
                                              )
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        {!loading ? (
                          <div className="notfound_img_div">
                            <div className="notfound_img"></div>
                          </div>
                        ) : (
                          <div className="notfound_img_div">
                            <div className="loadingfound_img"></div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="col-md-3 mt-2 order-1 order-md-2">
              <div className="card p-3">
                {details.is_stopped === 1 ? (
                  <>
                    <Label
                      size="large"
                      style={{ padding: "12px 12px 9px 12px" }}
                      color="red"
                    >
                      <FaRegStopCircle
                        style={{ marginTop: "-3px", fontSize: "1.5rem" }}
                      />{" "}
                      Requirement Stopped
                    </Label>
                  </>
                ) : (
                  <>
                    <Label
                      size="large"
                      style={{ padding: "12px 12px 9px 12px" }}
                      color="green"
                    >
                      <GiStopwatch
                        style={{ marginTop: "-3px", fontSize: "1.5rem" }}
                      />{" "}
                      OnGoing
                    </Label>
                  </>
                )}

                <p className="mb-0 mt-2">Start Time:</p>
                <b style={{ color: "black" }}>
                  {details.starting_time ? (
                    new Date(details.starting_time).toLocaleDateString(
                      "en-GB",
                      {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }
                    ) +
                    ", " +
                    new Date(details.starting_time).toLocaleTimeString(
                      "en-US",
                      {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    )
                  ) : (
                    <span style={{ color: "grey" }}>N/A</span>
                  )}

                  {/* {new Date(details.starting_time).toLocaleString()} */}
                </b>

                <p className="mb-0 mt-2">End Time:</p>
                <b style={{ color: "black" }}>
                  {details.ending_time ? (
                    new Date(details.ending_time).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }) +
                    ", " +
                    new Date(details.ending_time).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })
                  ) : (
                    <span style={{ color: "grey" }}>N/A</span>
                  )}
                </b>

                <p className="mb-0 mt-2">Stopped By:</p>
                <b style={{ color: "black" }}>
                  {details.stopped_by || (
                    <span style={{ color: "grey" }}>N/A</span>
                  )}
                </b>
              </div>

              {details.is_stopped === 1 ? null : (
                <div className="card p-3 mt-2">
                  <div className="d-flex gap-1 justify-content-start">
                    <Button
                      className="w-100"
                      style={{ height: "50px", fontSize: "18px" }}
                      variant="contained"
                      color="error"
                      onClick={() => handleStopClick(id)}
                    >
                      <b>Stop</b>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Dialog open={openModal} onClose={handleCloseStopModal}>
        <DialogTitle>Confirm Stop</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to stop the process with ID: {id}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseStopModal}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmStop}
            color="primary"
            variant="outlined"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Product */}
      <Dialog
        open={openDeleteProduct}
        onClose={handleCloseRemoveProduct}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the product{" "}
            <b style={{ color: "red" }}>({toRemoveSKU})</b> from Requirement
            list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseRemoveProduct}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            variant="outlined"
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RequirementDetails;
