import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
} from "@mui/material";
import { add_shipping_method, apiKey } from "../../../Api";
import toast from "react-hot-toast";
import axios from "axios";

function AddShippingMethodModal({ open, onClose, onAdd }) {
    const [shippingMethod, setShippingMethod] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!shippingMethod.trim()) {
            toast.error("Shipping method name is required!");
            return;
        }

        setLoading(true);
        const data = { shipping_method: shippingMethod };

        axios
            .post(add_shipping_method, data, {
                headers: {
                    "Content-Type": "application/json",
                    "API-Key": apiKey,
                },
            })
            .then((response) => {
                if (response.data.status === 1) {
                    toast.success("Shipping method added successfully!");
                    onAdd(response.data.data); // Pass the new method to update the list
                    setShippingMethod("");
                    onClose(); // Close modal
                } else {
                    toast.error(response.data.message || "Something went wrong!");
                }
            })
            .catch((error) => {
                toast.error(error.response?.data?.message || "Internal server error");
                console.error("Error while adding shipping method:", error?.response);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog open={open} onClose={!loading ? onClose : null} maxWidth="sm" fullWidth>
            <DialogTitle className="mb-0 pb-0">Add Shipping Method</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        label="Shipping Method"
                        fullWidth
                        size="small"
                        value={shippingMethod}
                        onChange={(e) => setShippingMethod(e.target.value)}
                        autoFocus
                        disabled={loading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="error" variant="outlined" disabled={loading}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : "Add"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default AddShippingMethodModal;
