import React, { useState, useEffect } from "react";
import axios from "axios";
import { useScreenWidth } from '../../../useScreenWidth';
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import { fetch_sale, apiKey, fetch_sale_detail } from "../../../Api";
import PrintSlipModal from "../../Sales/PrintSlipModal";
import toast, { Toaster } from "react-hot-toast";
import FilterDateRangeModal from "../../../Components/FilterModal/FilterDateRangeModal";
import { Icon, Label, Pagination } from "semantic-ui-react";
import { format, parseISO } from "date-fns";

function CustomerAllOrderDetails({ id }) {


    const isWideScreen = useScreenWidth();
    const [sales, setSales] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(0);
    const [searchquery, setSearchQuery] = useState("");
    const [filterModel, setFilterModel] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

    // date Filter
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const navigate = useNavigate();


    const handleDateChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    useEffect(() => {
        fetchData();
    }, [
        currentPage,
        pageSize,
        searchquery,
        filterModel,
        startDate,
        endDate,
        id
    ]);

    const fetchData = (page = currentPage, query = searchquery) => {
        const data = {
            page: parseInt(page),
            per_page: parseInt(pageSize),
            search: query,
            selected_customers: [id] || null,
            filters: filterModel.items || [],
            starting_date: startDate || "",
            ending_date: endDate || "",
        };
        const headers = {
            "Content-Type": "application/json",
            "API-Key": apiKey,
        };
        axios
            .post(fetch_sale, data, { headers })
            .then((response) => {

                // console.log("data : ",data)
                // console.log("response.data.data : ",response.data.data)
                setSales(response.data.data);
                setTotalPages(response.data.num_pages);
                setLoading(false);
            })
            .catch((error) => {
                toast.error(error.response?.data?.message);
                setLoading(false);
            });
    };


    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        setCurrentPage(1);
    };

    const handlePageChange = (e, { activePage }) => {
        setCurrentPage(activePage);
    };

    const handlePageSizeChange = (e) => {
        setPageSize(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleViewClick = (id) => {
        window.open(`/#/view-sale/${id}`, "_blank");
    };
    

    const [tqty, setTqty] = useState(0);
    const [data, setData] = useState([]);
    const [addressdata, setAddressData] = useState([]);
    const [saleProducts, setSaleProducts] = useState([]);

    const handlePrintSlip = (pk) => {
        axios
            .get(fetch_sale_detail, {
                headers: {
                    "API-Key": apiKey,
                },
                params: {
                    sale_pk: parseInt(pk),
                },
            })
            .then((response) => {
                setData(response.data.data);
                setTqty(
                    response.data.data.items.reduce((sum, item) => sum + item.quantity, 0)
                );
                setAddressData(response.data.data.billing_customer);
                setSaleProducts(response.data.data.items);
            })
            .catch((error) => {
                console.log(error);
            });

        setOpenModal(true);
    };

    function formatIndianRupee(amount) {
        const formatter = new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: 2,
        });
        return formatter.format(amount);
    }


    return (
        <>
        {openModal && (
            <PrintSlipModal
                tqty={tqty}
                openModal={openModal}
                setOpenModal={setOpenModal}
                fetchData={fetchData}
                saleProducts={saleProducts}
                data={data}
                addressdata={addressdata}
            />
        )}
            <Toaster position="top-right" />

            <div className="card my-2 p-2">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <input
                            className="form-control rounded"
                            placeholder="Search..."
                            style={{ backgroundColor: "white" }}
                            type="search"
                            value={searchquery}
                            onChange={handleSearchChange}
                        />
                    </div>


                </div>
            </div>

            {isWideScreen ? (
                <div className="responsive-table1" style={{ maxHeight: "30vh" }}>
                    <table className="ui table celled " id="c_table">
                        <thead className="table-head">
                            <tr>
                                <th style={{ width: "2px" }}>#</th>
                                <th>
                                    <FilterDateRangeModal
                                        title="Sale Date"
                                        startDate={startDate}
                                        endDate={endDate}
                                        onDateChange={handleDateChange}
                                    />
                                </th>
                                <th className="text-center">Bill</th>
                                <th>Created By</th>
                                <th className="text-center">Total Products</th>
                                <th style={{ textAlign: "right" }}>Amount</th>

                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sales.length > 0 ? (
                                <>
                                    {sales &&
                                        sales.map((data, index) => (
                                            <>
                                                <tr>
                                                    <td
                                                        style={{
                                                            width: "20px",
                                                            backgroundColor:
                                                                data.is_cancelled == 1
                                                                    ? "#ffe8e6"
                                                                    : "transparent",
                                                        }}
                                                    >
                                                        {data.id}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: "160px",
                                                            backgroundColor:
                                                                data.is_cancelled == 1
                                                                    ? "#ffe8e6"
                                                                    : "transparent",
                                                        }}
                                                    >
                                                        <b>
                                                            {format(
                                                                parseISO(data.sale_date),
                                                                "dd MMMM, yyyy"
                                                            )}
                                                        </b>
                                                    </td>

                                                    <td
                                                        style={{
                                                            backgroundColor:
                                                                data.is_cancelled == 1
                                                                    ? "#ffe8e6"
                                                                    : "transparent",
                                                        }}
                                                    >
                                                        <b className="d-flex justify-content-center">



                                                            {["Karan", "Tech", "Vipin"].some((name) =>
                                                                userDetails.name?.includes(name)
                                                            ) ? (
                                                                data.is_skd === 1 ? (
                                                                    <span className="badge bg-warning ms-2">SKD</span>
                                                                ) : data.is_nkd === 1 ? (
                                                                    <span className="badge bg-success ms-2">NKD</span>
                                                                ) : null
                                                            ) : null}
                                                        </b>
                                                    </td>

                                                    <td
                                                        style={{
                                                            backgroundColor:
                                                                data.is_cancelled == 1
                                                                    ? "#ffe8e6"
                                                                    : "transparent",
                                                        }}
                                                    >
                                                        {data.created_by}
                                                    </td>

                                                    <td
                                                        style={{
                                                            textAlign: "center",
                                                            backgroundColor:
                                                                data.is_cancelled == 1
                                                                    ? "#ffe8e6"
                                                                    : "transparent",
                                                        }}
                                                    >
                                                        <span className="tbody_hint">
                                                            No. of Products:{" "}
                                                        </span>
                                                        {data.total_products} QTY
                                                    </td>
                                                    <td
                                                        style={{
                                                            color: "green",
                                                            textAlign: "right",
                                                            backgroundColor:
                                                                data.is_cancelled == 1
                                                                    ? "#ffe8e6"
                                                                    : "transparent",
                                                        }}
                                                    >
                                                        <b>
                                                            {formatIndianRupee(
                                                                data.total_price -
                                                                data.discount_amount +
                                                                data.courier_charge +
                                                                data.other_charge || "N/A"
                                                            )}
                                                        </b>
                                                    </td>

                                                    <td
                                                        style={{
                                                            maxWidth: "200px",
                                                            backgroundColor:
                                                                data.is_cancelled == 1
                                                                    ? "#ffe8e6"
                                                                    : "transparent",
                                                        }}
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={() => handleViewClick(data.id)}
                                                            style={{ marginLeft: "5px" }}
                                                        >
                                                            View
                                                        </Button>

                                                        {data.type_of_sale === 2 ? (
                                                            <>
                                                                {data.dispatched_status === 1 ||
                                                                    data.is_cancelled === 1 ? (
                                                                    <Button
                                                                        style={{ marginLeft: "5px" }}
                                                                        variant="outlined"
                                                                        color="info"
                                                                        size="small"
                                                                        onClick={() =>
                                                                            navigate(
                                                                                `/dispatch-details/${data.id}`
                                                                            )
                                                                        }
                                                                    >
                                                                        D. History
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        style={{ marginLeft: "5px" }}
                                                                        variant="outlined"
                                                                        color="info"
                                                                        size="small"
                                                                        onClick={() =>
                                                                            navigate(
                                                                                `/dispatch-sale-scan/${data.id}`
                                                                            )
                                                                        }
                                                                    >
                                                                        Dispatch
                                                                    </Button>
                                                                )}
                                                            </>
                                                        ) : null}

                                                        <Button
                                                            disabled={data.is_cancelled === 1}
                                                            variant="outlined"
                                                            style={{ marginLeft: "5px" }}
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => handlePrintSlip(data.id)}
                                                        >
                                                            Print
                                                        </Button>

                                                        {/* {data.type_of_sale === 2 ? (
                                                            <>
                                                                <Button
                                                                    style={{ marginLeft: "5px" }}
                                                                    disabled={
                                                                        data.dispatched_status === 1 ||
                                                                        data.dispatched_status === 2 ||
                                                                        data.is_cancelled === 1
                                                                    }
                                                                    variant="outlined"
                                                                    color="error"
                                                                    size="small"
                                                                    onClick={() => handleCancelSale(data.id)}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </>
                                                        ) : null} */}
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                </>
                            ) : (
                                <>
                                    <tr>
                                        <td colSpan={7}>
                                            {!loading ? (
                                                <p className="text-center">No Sales Found</p>
                                            ) : (
                                                <div className="notfound_img_div" style={{height:"20vh"}}>
                                                    <div className="loadingfound_img"></div>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <>
                    <div className="card p-2 responsive-mobile-table" style={{ maxHeight: "50vh" }}>
                        {sales.length > 0 ? (
                            <>
                                {sales &&
                                    sales.map((data) => (
                                        <>
                                            <div
                                                className="product-card"
                                                style={{
                                                    position: "relative",
                                                    backgroundColor:
                                                        data.is_cancelled == 1
                                                            ? "#ffe8e6"
                                                            : "transparent",
                                                }}
                                            // onClick={() => handleViewClick(data.id)}
                                            >
                                                <div className="py-1 px-2">
                                                    <h5 className="product-card-sku">#{data.id}</h5>
                                                    <h5
                                                        className="sale-card-customer"
                                                        onClick={() => handleViewClick(data.id)}
                                                    >
                                                        {data.billing_customer?.customer_name || "N/A"}
                                                    </h5>
                                                    <h5 className="sale-card-date">
                                                        <span style={{ color: "green" }}>
                                                            {formatIndianRupee(
                                                                data.total_price -
                                                                data.discount_amount +
                                                                data.courier_charge +
                                                                data.other_charge || "N/A"
                                                            )}
                                                        </span>{" "}
                                                        | <span>{data.total_products} QTY</span> |{" "}
                                                        <span>{data.sale_date}</span>
                                                    </h5>

                                                    <Label
                                                        style={{
                                                            position: "absolute",
                                                            top: "2px",
                                                            right: "2px",
                                                            fontSize: "10px",
                                                        }}
                                                        color={
                                                            data.type_of_sale === 2 ? "orange" : "teal"
                                                        }
                                                        size="small"
                                                        tag
                                                    >
                                                        {data.type_of_sale == 2
                                                            ? "Party Sale"
                                                            : "Cash Sale"}
                                                    </Label>

                                                    {data.type_of_sale === 2 ? (
                                                        <>
                                                            {data.dispatched_status === 1 ||
                                                                data.is_cancelled === 1 ? null : (
                                                                <Button
                                                                    className="mt-2"
                                                                    variant="outlined"
                                                                    color="info"
                                                                    size="small"
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/dispatch-sale-scan/${data.id}`
                                                                        )
                                                                    }
                                                                >
                                                                    Dispatch
                                                                </Button>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </>
                                    ))}
                            </>
                        ) : (
                            <>
                                {!loading ? (
                                    <div className="notfound_img_div">
                                        <div className="notfound_img"></div>
                                    </div>
                                ) : (
                                    <div className="notfound_img_div">
                                        <div className="loadingfound_img"></div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}

            <div style={{ marginTop: "2px", display: "flex" }}>
                <select
                    className="form-control mb_btn_hide"
                    style={{
                        width: "50px",
                        marginRight: "5px",
                        backgroundColor: "white",
                    }}
                    value={pageSize}
                    onChange={handlePageSizeChange}
                >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                <Pagination
                    size="tiny"
                    defaultActivePage={1}
                    onPageChange={handlePageChange}
                    ellipsisItem={{
                        content: <Icon name="ellipsis horizontal" />,
                        icon: true,
                    }}
                    firstItem={{
                        content: <Icon name="angle double left" />,
                        icon: true,
                    }}
                    lastItem={{
                        content: <Icon name="angle double right" />,
                        icon: true,
                    }}
                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                    totalPages={totalPages}
                />
            </div>

        </>
    )
}

export default CustomerAllOrderDetails
