import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IoMdArrowRoundBack, IoMdCopy } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { MdContentCopy, MdOutlineDoubleArrow } from "react-icons/md";
import { Breadcrumbs, Typography, Button, Modal, Box } from "@mui/material";
import { useScreenWidth } from '../../../useScreenWidth';
import toast from 'react-hot-toast';
import CustomerAllOrderDetails from './CustomerAllOrderDetails';
import CustomerOrderProducts from './CustomerOrderProducts';
import { apiKey, getCustomerDetail } from '../../../Api';
import axios from 'axios';
import EditCustomer from './EditCustomer';

function CustomerDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const isWideScreen = useScreenWidth();
    const [customer, setCustomer] = useState(null);

    const fetchCustomer = () => {
        const headers = {
            "API-Key": apiKey,
        };

        axios.get(getCustomerDetail, {
            params: {
                customer_pk: id,
            },

            headers,
        })
            .then((response) => {
                const customer = response.data.customer;
                // console.log("customer : ", customer);
                setCustomer(customer);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (id) {
            fetchCustomer();
        }
    }, [id]);


    // Edit Customer

    const [selectedEditCustomer, setSelectedEditCustomer] = useState();
    const [openEditCustomerModal, setOpenEditCustomerModal] = useState(false);

    const handleEditCustomer = (customer) => {
        // console.log(customer);
        setSelectedEditCustomer(customer);
        setOpenEditCustomerModal(true);
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 1400,
        minWidth: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };


    return (
        <>


            <Modal
                open={openEditCustomerModal}
                onClose={() => setOpenEditCustomerModal(false)}
            >
                <Box sx={style}>
                    <EditCustomer
                        customerEditDetail={selectedEditCustomer}
                        apiKey={apiKey}
                        closeModal={() => setOpenEditCustomerModal(false)}
                        fetchCustomers={fetchCustomer}
                    />
                </Box>
            </Modal>

            <div className="main-panel">
                <div className="content-wrapper">

                    <div className='d-flex'>
                        <IoMdArrowRoundBack
                            id="backbtn"
                            onClick={() => navigate("/all-customers")}
                        />
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography color="inherit">Customers</Typography>
                            <Typography color="inherit">Customer Detail </Typography>
                            <Typography sx={{ color: "text.primary" }}>(#{id})</Typography>
                        </Breadcrumbs>
                    </div>

                    <section>
                        <div className="row position-relative mt-2">
                            <div className="col-xl-3">

                                {customer ? (
                                    <div className="card rounded px-3 py-3 position-relative">
                                        <div className="d-flex align-items-start justify-content-between">
                                            <h4 className='mb-0 d-flex align-items-center'> <MdOutlineDoubleArrow /> Customer</h4>
                                            <div className='d-flex align-items-center '>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    size="small"
                                                    onClick={() => handleEditCustomer(customer)}
                                                    style={{minWidth:"40px" , height:"30px"}}
                                                >
                                                    <FiEdit  />

                                                </Button>
                                                <Button
                                                    className='ms-2'
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    style={{minWidth:"40px" , height:"30px"}}
                                                    onClick={() => {
                                                        const details = `Name: ${customer?.customer_name || "N/A"}\nPhone: ${customer?.phone || "N/A"}\nAlternative Phone: ${customer?.alternativ_phone || "N/A"}\nEmail: ${customer?.email || "N/A"}\nGST Number: ${customer?.gst_number || "N/A"}\nCategory: ${customer?.category_ref?.category || "N/A"}`;
                                                        navigator.clipboard.writeText(details);
                                                        toast.success("Customer details copied!");
                                                    }}
                                                >
                                                    <MdContentCopy />

                                                </Button>

                                            </div>

                                        </div>
                                        <div className="row my-2">
                                            <div className="col-4">Name:</div>
                                            <div className="col-8">
                                                <b style={{ color: "black" }}>{customer?.customer_name || "N/A"}</b>
                                                {customer?.customer_name && (
                                                    <MdContentCopy
                                                        style={{ cursor: "pointer", marginLeft: "5px" }}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(customer.customer_name);
                                                            toast.success("Name copied to clipboard!");
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="row my-2">
                                            <div className="col-4">Phone:</div>
                                            <div className="col-8">
                                                {customer?.phone ? (
                                                    <>
                                                        <a href={`tel:${customer.phone}`} style={{ textDecoration: "none", color: "#007bff" }}>{customer.phone}</a>
                                                        <MdContentCopy
                                                            style={{ cursor: "pointer", marginLeft: "5px" }}
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(customer.phone);
                                                                toast.success("Number copied to clipboard!");
                                                            }}
                                                        />
                                                    </>
                                                ) : "Not provided"}
                                            </div>
                                        </div>
                                        <div className="row my-2">
                                            <div className="col-4">Alt Phone:</div>
                                            <div className="col-8">{customer?.alternativ_phone || "N/A"}</div>
                                        </div>
                                        <div className="row my-2">
                                            <div className="col-4">Email:</div>
                                            <div className="col-8">
                                                {customer?.email ? (
                                                    <>
                                                        <a href={`mailto:${customer.email}`} style={{ textDecoration: "none", color: "#007bff" }}>{customer.email}</a>
                                                        <MdContentCopy
                                                            style={{ cursor: "pointer", marginLeft: "5px" }}
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(customer.email);
                                                                toast.success("Email copied to clipboard!");
                                                            }}
                                                        />
                                                    </>
                                                ) : "Not provided"}
                                            </div>
                                        </div>
                                        <div className="row my-2">
                                            <div className="col-4">GST Number:</div>
                                            <div className="col-8">{customer?.gst_number || "N/A"}</div>
                                        </div>
                                        <div className="row my-2">
                                            <div className="col-4">Category:</div>
                                            <div className="col-8">{customer?.category_ref?.category || "N/A"}</div>
                                        </div>
                                    </div>

                                ) : (
                                    <Typography>Loading customer details...</Typography>
                                )}

                            </div>


                            <div className="col-xl-9">
                                <CustomerAllOrderDetails id={id} />
                                <CustomerOrderProducts id={id} />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default CustomerDetail
