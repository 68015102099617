import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { apiKey, fetch_warehouse, fetch_purchase_detail, fetch_rows, fetch_racks, allocate_purchase_item } from "../../Api";
import { useParams, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Autocomplete, Breadcrumbs, Button, TextField, Typography } from "@mui/material";
import { Pagination, Icon, Label } from "semantic-ui-react";
import { useScreenWidth } from "../../useScreenWidth";
import AllocateProductInRowRack from "./AllocateProductInRowRack";
import PrintBarcode from "./PrintBarcode";
import ReceiveAllModal from "./RcvAlltoWarehouseModal";
import { SiTicktick } from "react-icons/si";
import toast from "react-hot-toast";

function ViewPurchase() {
  const isWideScreen = useScreenWidth();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [products_count, setProducts_Count] = useState(0);
  const [total_quantity, setTotalQty] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchquery, setSearchQuery] = useState("");
  const [purchase, setPurchase] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [rcvwarehouse, setRcvWarehouse] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    fetchPurchases();
  }, [id]);

  const fetchPurchases = (page = currentPage, query = searchquery) => {
    const bodyData = {
      purchase_pk: parseInt(id),
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
    };
    axios
      .post(fetch_purchase_detail, bodyData, {
        headers: {
          "API-Key": apiKey,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response.data.purchase_detail.items_detail)
        setLoading(false);
        setTotalPages(response.data.num_pages);
        setTotalQty(response.data.total_quantity);
        setProducts_Count(response.data.products_count);
        setPurchase(response.data.purchase_detail);
        setSelectedProducts(response.data.purchase_detail.items_detail);
        setRcvWarehouse(response.data.purchase_detail.received_warehouse);
        if (response.data.purchase_detail.received_warehouse_pk) {
          fetchRows(response.data.purchase_detail.received_warehouse_pk);
        }
        const initialQuantities = {};
        response.data.purchase_detail.items_detail.forEach((item) => {
          initialQuantities[item.product_sku] = item.quantity;
        });
        // setQuantities(initialQuantities);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPurchases();
  }, [currentPage, pageSize, searchquery]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    // if (query.length > 2){
    setSearchQuery(query);
    setCurrentPage(1);

    // }
  };

  // Pagination

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const [
    openAllocateProductInRowRackModal,
    setOpenAllocateProductInRowRackModal,
  ] = useState(false);
  const [selectedAllocateProduct, setSelectedAllocateProduct] = useState(null);

  const handleCloseAllocateProductModal = () => {
    setOpenAllocateProductInRowRackModal(false);
    setSelectedAllocateProduct(null);
  };

  // Print Barcode.
  const [openBarcode, setOpenBarcode] = useState(false);
  const [barcodes, setBarcodes] = useState([]);

  const handlePrintBarcode = (data) => {
    const formattedData = data.serieses.map((series) => ({
      series: series,
      sku: data.product_sku,
      brand: data.product_brand,
      part_number: data.part_number,
      name: data.product_name,
    }));
    setBarcodes(formattedData);
    setOpenBarcode(true);
  };

  const handleCloseBarcode = () => {
    setOpenBarcode(false);
  };

  const [warehouses, setWarehouses] = useState([]);
  const [openModalWarehouse, setOpenModalWarehouse] = useState(false);

  const handleRcvAll = () => {
    try {
      axios
        .get(fetch_warehouse, {
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          // console.log(response);
          setWarehouses(response.data.warehouses);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch {
      console.log("error");
      setOpenModalWarehouse(false);
    }
    setOpenModalWarehouse(true);
  };




  const [rows, setRows] = useState([]);
  const [racks, setRacks] = useState([]);

  const fetchRows = async (warehouseId) => {
    setLoading((prev) => ({ ...prev, rows: true }));
    try {
      const response = await axios.get(fetch_rows, {
        params: { warehouse_id: warehouseId },
        headers: { "API-Key": apiKey },
      });
      setRows(response.data.rows);
    } catch (error) {
      toast.error("Failed to fetch rows.");
    } finally {
      setLoading((prev) => ({ ...prev, rows: false }));
    }
  };

  const fetchRacks = async (rowId) => {
    setLoading((prev) => ({ ...prev, racks: true }));
    try {
      const response = await axios.get(fetch_racks, {
        params: { row_pk: rowId },
        headers: { "API-Key": apiKey },
      });
      setRacks(response.data.racks);
    } catch (error) {
      toast.error("Failed to fetch racks.");
    } finally {
      setLoading((prev) => ({ ...prev, racks: false }));
    }
  };



  // Allocate Product
  const handleRowChange = (sku, row) => {
    setSelectedProducts((prevDetails) =>
      prevDetails.map((detail) =>
        detail.product_sku === sku
          ? { ...detail, selectedRow: row, selectedRack: "" }
          : detail
      )
    );

    if (row) {
      fetchRacks(row.id); // Fetch racks based on selected row
      setRacks([]); // Reset racks
    }
  };

  const handleRackChange = (sku, rack) => {
    setSelectedProducts((prevDetails) =>
      prevDetails.map((detail) =>
        detail.product_sku === sku ? { ...detail, selectedRack: rack } : detail
      )
    );
  };


  const handleAllocateProduct = (product) => {
    const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

    if (!product.selectedRack) {
      return toast.error("Please Select Rack");
    }

    const sendData = {
      purchase_item_pk: parseInt(product.id),
      rack_pk: parseInt(product.selectedRack.id),
      user_name: userDetails?.name,
    };

    // console.log(sendData)

    try {
      axios
        .post(allocate_purchase_item, sendData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            toast.success("Allocated successfully!");
            // fetchData();
            setSelectedProducts((prevDetails) =>
              prevDetails.map((p) =>
                p.product_sku === product.product_sku
                  ? { ...p, is_allocatable: 0 }
                  : p
              )
            );
          } else {
            toast.error(response?.data?.message || "Something went wrong");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error?.response?.data?.message);
        });
    } catch (error) {
      console.log("error While Allocating Product :", error?.response);
    }
  };

  return (
    <>
      <ReceiveAllModal
        setOpenModalWarehouse={setOpenModalWarehouse}
        openModalWarehouse={openModalWarehouse}
        warehouses={warehouses}
        purchase_pk={id}
      />
      <PrintBarcode
        openBarcode={openBarcode}
        handleCloseBarcode={handleCloseBarcode}
        barcodes={barcodes}
      />

      <AllocateProductInRowRack
        open={openAllocateProductInRowRackModal}
        onClose={handleCloseAllocateProductModal}
        product={selectedAllocateProduct}
        warehouseId={9}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_purchases")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Purchases</Typography>
              <Typography color="inherit">Purchase Detail</Typography>
              <Typography sx={{ color: "text.primary" }}>#{id}</Typography>
            </Breadcrumbs>
          </div>

          <div className="row my-2">
            <div className="col-xl-3">
              <div className="card p-3">
                <div className="row my-2">
                  <div className="col-3">Vendor :</div>
                  <div className="col-9">
                    <b style={{ color: "black" }}>
                      {purchase && purchase.vendor_ref?.name}
                    </b>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-3">Date :</div>

                  <div className="col-9">
                    <b style={{ color: "black" }}>
                      {/* {purchase && purchase?.purchase_date} */}
                      {new Date(purchase.purchase_date).toLocaleDateString(
                        "en-US",
                        {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                    </b>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-3">Created by :</div>

                  <div className="col-9">
                    <b style={{ color: "black" }}>
                      {purchase && purchase?.created_by}
                    </b>
                  </div>
                </div>

                <div className="row my-2">
                  <div className="col-3">Bill to : </div>
                  <div className="col-9" style={{ color: "black" }}>
                    <b>Kuber Turbo Tech</b>
                    <br></br>
                    Delhi, IN
                  </div>
                </div>
              </div>

              <div className="card p-3 mt-2">
                <h4>Purchase Summary</h4>
                <div className="row" style={{ marginTop: "0px" }}>
                  <div className="col-6">Total Products</div>
                  <div
                    className="col-6"
                    style={{ textAlign: "right", color: "black" }}
                  >
                    <b>{products_count}</b>
                  </div>

                  <div className="col-6 my-2">Total Products Quantity</div>
                  <div
                    className="col-6 my-2"
                    style={{ textAlign: "right", color: "black" }}
                  >
                    <b>{total_quantity}</b>
                  </div>
                  <div className="col-6 my-2">Received Warehouse:</div>
                  <div
                    className="col-6 my-2"
                    style={{ textAlign: "right", color: "black" }}
                  >
                    <h5>{rcvwarehouse}</h5>
                  </div>
                </div>
              </div>

              {!rcvwarehouse ? (
                <>
                  <div className="card p-3 mt-2">
                    <Button
                      onClick={() => handleRcvAll()}
                      variant="contained"
                      size="medium"
                    >
                      Recieve Products
                    </Button>
                  </div>
                </>
              ) : null}
            </div>
            <div className="col-xl-9">
              <div className="card p-2">
                <div className="row">
                  <div className="col-lg-6">
                    <input
                      className="form-control"
                      placeholder="Search..."
                      style={{ backgroundColor: "white" }}
                      type="search"
                      value={searchquery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
              {isWideScreen ? (
                <>
                  <div
                    className="responsive-table1 mt-2"
                    style={{ maxHeight: "75vh" }}
                  >
                    <table className="ui table celled" id="c_table">
                      <thead className="table-head">
                        <tr>
                          <th>SKU</th>
                          <th>Product</th>
                          <th>Part No.</th>
                          <th>OEM No.</th>
                          <th className="text-center">Quantity</th>
                          {rcvwarehouse ? (
                            <>
                              <th className="text-center">Allocate</th>
                              <th className="text-center">Actions</th>
                            </>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProducts &&
                          selectedProducts.map((data) => (
                            <tr key={data.id}>
                              <td
                                style={{ maxWidth: "60px", textWrap: "wrap" }}
                              >
                                {data.product_sku}
                              </td>
                              <td
                                className="product_name_class"
                                style={{
                                  maxWidth: "190px",
                                }}
                              >
                                {data.product_name}
                              </td>
                              <td>{data.part_number}</td>
                              <td
                                style={{ maxWidth: "190px", textWrap: "wrap" }}
                              >
                                {data.oem_number}
                              </td>
                              <td className="text-center"> {data.quantity} </td>
                              {rcvwarehouse ? (
                                <>

                                  <td style={{ maxWidth: "200px", overflow: "auto" }}>
                                    {data.is_allocatable === 0 ? (
                                      <p style={{ color: "red", textAlign: "center", }} >
                                        Can't Allocate
                                      </p>
                                    ) : (
                                      <>
                                        <div className="d-flex align-items-center justify-content-between gap-1">
                                          <Autocomplete
                                            disabled={
                                              data.is_allocatable == 0
                                            }
                                            fullWidth
                                            options={rows}
                                            getOptionLabel={(option) =>
                                              option.row_name
                                            }
                                            value={data.selectedRow || null} // Pass full row object
                                            onChange={(e, newValue) => {
                                              handleRowChange(
                                                data.product_sku,
                                                newValue || null
                                              );
                                            }}
                                            style={{ width: "100px" }}
                                            loading={loading.rows}
                                            renderInput={(params) => (
                                              <TextField
                                                fullWidth
                                                {...params}
                                                label="Row"
                                                variant="outlined"
                                                size="small"
                                              />
                                            )}
                                          />

                                          <Autocomplete
                                            disabled={
                                              data.is_allocatable == 0
                                            }
                                            fullWidth
                                            style={{ width: "100px" }}
                                            options={racks}
                                            getOptionLabel={(option) =>
                                              option.rack_name
                                            }
                                            value={data.selectedRack || null} // Pass full rack object
                                            onChange={(e, newValue) => {
                                              handleRackChange(
                                                data.product_sku,
                                                newValue || null
                                              );
                                            }}
                                            loading={loading.racks}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Rack"
                                                variant="outlined"
                                                size="small"
                                              />
                                            )}
                                          />

                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                              handleAllocateProduct(data)
                                            }
                                            style={{ padding: "10px" }}
                                            disabled={
                                              !data.selectedRow ||
                                              !data.selectedRack
                                            }
                                          >
                                            <SiTicktick />
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  </td>


                                  <td className="text-center">
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      className="me-2"
                                      color="success"
                                      onClick={() => handlePrintBarcode(data)}
                                    >
                                      Print
                                    </Button>
                                  </td>
                                </>
                              ) : null}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div style={{ marginTop: "5px", display: "flex" }}>
                    <select
                      className="form-control mb_btn_hide"
                      style={{
                        width: "50px",
                        marginRight: "5px",
                        backgroundColor: "white",
                      }}
                      value={pageSize}
                      onChange={handlePageSizeChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    <Pagination
                      size="tiny"
                      defaultActivePage={1}
                      onPageChange={handlePageChange}
                      ellipsisItem={{
                        content: <Icon name="ellipsis horizontal" />,
                        icon: true,
                      }}
                      firstItem={{
                        content: <Icon name="angle double left" />,
                        icon: true,
                      }}
                      lastItem={{
                        content: <Icon name="angle double right" />,
                        icon: true,
                      }}
                      prevItem={{
                        content: <Icon name="angle left" />,
                        icon: true,
                      }}
                      nextItem={{
                        content: <Icon name="angle right" />,
                        icon: true,
                      }}
                      totalPages={totalPages}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="card p-2 responsive-mobile-table">
                    {selectedProducts.length > 0 ? (
                      <>
                        {selectedProducts &&
                          selectedProducts.map((data) => (
                            <>
                              <div className="product-card">
                                <div className="py-1 px-2">
                                  <h5 className="product-card-sku">
                                    #{data.product_sku}
                                  </h5>
                                  <h5 className="product-card-name">
                                    {data.product_name}
                                  </h5>
                                  
                                  <small>
                                    {data.part_number} || {data.oem_number}
                                  </small>

                                  <Label
                                    color={"green"}
                                    style={{
                                      position: "absolute",
                                      top: "4%",
                                      right: "5px",
                                      fontSize: "11px",
                                    }}
                                    className="product-card-mrp"
                                  >
                                    {data.quantity} QTY
                                  </Label>


                                  {rcvwarehouse ? (
                                    <>
                                      {data.is_allocatable === 0 ? (
                                        null
                                      ) : (
                                        <>
                                          <div className="d-flex align-items-center justify-content-between gap-1 w-100">
                                            <Autocomplete
                                              disabled={
                                                data.is_allocatable == 0
                                              }
                                              fullWidth
                                              options={rows}
                                              getOptionLabel={(option) =>
                                                option.row_name
                                              }
                                              value={data.selectedRow || null} // Pass full row object
                                              onChange={(e, newValue) => {
                                                handleRowChange(
                                                  data.product_sku,
                                                  newValue || null
                                                );
                                              }}
                                              style={{ width: "100px" }}
                                              loading={loading.rows}
                                              renderInput={(params) => (
                                                <TextField
                                                  fullWidth
                                                  {...params}
                                                  label="Row"
                                                  variant="outlined"
                                                  size="small"
                                                />
                                              )}
                                            />

                                            <Autocomplete
                                              disabled={
                                                data.is_allocatable == 0
                                              }
                                              fullWidth
                                              style={{ width: "100px" }}
                                              options={racks}
                                              getOptionLabel={(option) =>
                                                option.rack_name
                                              }
                                              value={data.selectedRack || null} // Pass full rack object
                                              onChange={(e, newValue) => {
                                                handleRackChange(
                                                  data.product_sku,
                                                  newValue || null
                                                );
                                              }}
                                              loading={loading.racks}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="Rack"
                                                  variant="outlined"
                                                  size="small"
                                                />
                                              )}
                                            />

                                            <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={() =>
                                                handleAllocateProduct(data)
                                              }
                                              style={{ padding: "10px" }}
                                              disabled={
                                                !data.selectedRow ||
                                                !data.selectedRack
                                              }
                                            >
                                              <SiTicktick />
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ) : null}



                                </div>
                              </div>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        {
                          <div className="notfound_img_div">
                            <div className="loadingfound_img"></div>
                          </div>
                        }
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewPurchase;
