import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { apiKey, requirement_details } from "../../Api";
import CustomLoader from "../../Components/Loader";
import { useScreenWidth } from "../../useScreenWidth";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import RemovePickupModal from "../../Messages/RemovePickupModal";
import { IoMdRemoveCircle } from "react-icons/io";
import { Toaster } from "react-hot-toast";

function DispatchRequirementDetails() {
  const [showRemoveQty, setShowRemoveQty] = useState(false);
  const [item_pk, setItemPk] = useState("");
  const [picked_qty, setPicked_Qty] = useState("");
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const isWideScreen = useScreenWidth();
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const ws = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Establish WebSocket connection with API-Key as a query parameter
    ws.current = new WebSocket(
      `${requirement_details}?requirement_pk=${id}&API-Key=${apiKey}`
    );

    ws.current.onopen = () => {
      // console.log("WebSocket connection established");
      setLoading(false);
    };

    ws.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      // console.log(response);
      setData(response.items);
      // Assuming response.data.purchase_detail.items_detail is the data you need
      // setData(response.data.purchase_detail.items_detail);
    };

    ws.current.onerror = (error) => {
      console.error(error, "WebSocket error");
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [id, apiKey]);

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, [searchQuery]);

  const fetchData = (query = searchQuery) => {
    const bodyData = {
      requirement_pk: parseInt(id),
      search: query,
    };

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(bodyData));
    }
  };

  const handlePickUp = (pk) => {
    navigate(`/requirement-pick-up/${pk}`);
  };

  const handleRemoveProduct = (item_id, picked) => {
    setShowRemoveQty(true);
    setPicked_Qty(picked);
    setItemPk(item_id);
  };

  return (
    <>
      <Toaster position="top-right" />
      <CustomLoader loading={loading} />

      <RemovePickupModal
        setShowRemoveQty={setShowRemoveQty}
        showRemoveQty={showRemoveQty}
        item_pk={item_pk}
        picked_qty={picked_qty}
        fetchData={fetchData}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Requirement</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Requirement Details
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="row my-1">
            {isWideScreen ? (
              <>
                <div
                  className="responsive-table1"
                  style={{ minHeight: "85vh" }}
                >
                  <table className="ui table celled" id="c_table">
                    <thead className="table-head">
                      <tr>
                        <th>#</th>
                        <th>Product SKU</th>
                        <th>Product Name</th>
                        <th>Product Type</th>
                        <th>Reqd. Qty</th>
                        <th>Picked Qty</th>
                        <th>Packed Qty</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        <>
                          {data &&
                            data.map((req, index) => (
                              <>
                                <tr
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      req.quantity < req.picked_quantity
                                          ? "rgb(253 199 199)"
                                          : req.quantity == req.picked_quantity
                                            ? "#d1e7dd"
                                            : req.quantity > req.picked_quantity &&
                                              req.picked_quantity != 0
                                              ? "#fff3cd"
                                              : req.picked_quantity == 0
                                                ? "transparent"
                                                : "transparent",
                                  }}
                                >
                                  <td style={{ width: "40px" }}>{index + 1}</td>
                                  <td>{req.product_sku}</td>
                                  <td>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handlePickUp(req.id)}
                                    >
                                      {req.product_name}
                                    </span>
                                  </td>
                                  <td>{req.product_type}</td>
                                  <td>{req.quantity}</td>
                                  <td>{req.picked_quantity}</td>
                                  <td>{req.packed_quantity}</td>
                                  <td style={{ backgroundColor: "grey" }}>
                                    <Button
                                      disabled={
                                        req.quantity === req.picked_quantity
                                      }
                                      variant="outlined"
                                      size="small"
                                      onClick={() => handlePickUp(req.id)}
                                    >
                                      Pick
                                    </Button>

                                    <Button
                                      disabled={
                                        req.packed_quantity ===
                                        req.picked_quantity
                                      }
                                      className="ms-2"
                                      variant="outlined"
                                      color="error"
                                      size="small"
                                      onClick={() =>
                                        handleRemoveProduct(
                                          req.id,
                                          req.picked_quantity
                                        )
                                      }
                                    >
                                      Remove
                                    </Button>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={10}>
                              {!loading ? (
                                <div className="notfound_img_div">
                                  <div className="notfound_img"></div>
                                </div>
                              ) : (
                                <div className="notfound_img_div">
                                  <div className="loadingfound_img"></div>
                                </div>
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div
                  className="card p-2 responsive-mobile-table"
                  style={{ minHeight: "85vh" }}
                >
                  {data.length > 0 ? (
                    <>
                      {data &&
                        data.map((req, index) => (
                          <>
                            <div
                              key={index}
                              className="product-card"
                              style={{
                                backgroundColor:
                                  req.quantity < req.picked_quantity
                                    ? "rgb(253 199 199)"
                                    : req.quantity == req.picked_quantity
                                      ? "#d1e7dd"
                                      : req.quantity > req.picked_quantity &&
                                        req.picked_quantity != 0
                                        ? "#fff3cd"
                                        : req.picked_quantity == 0
                                          ? "transparent"
                                          : "transparent",
                              }}
                            >
                              <div className="py-1 px-2">
                                <h5 className="product-card-sku">
                                  #{req.product_sku}
                                </h5>
                                <h5
                                  className="product-card-name"
                                  onClick={() => handlePickUp(req.id)}
                                >
                                  {req.product_name}
                                </h5>
                                <h5 className="product-card-mrp">
                                  {req.product_type}
                                </h5>

                                <div className="d-flex gap-2">
                                  <h5 className="product-card-mrp">
                                    Required Qty: {req.quantity}{" "}
                                  </h5>
                                  <h5 className="product-card-mrp">
                                    | Picked Qty: {req.picked_quantity}
                                  </h5>
                                </div>

                                <div
                                  className="d-flex gap-1"
                                  style={{
                                    position: "absolute",
                                    bottom: "5px",
                                    right: "5px",
                                  }}
                                >
                                  <Button
                                    disabled={
                                      req.quantity === req.picked_quantity
                                    }
                                    variant="outlined"
                                    size="small"
                                    onClick={() => handlePickUp(req.id)}
                                  >
                                    Pick
                                  </Button>

                                  <Button
                                    disabled={
                                      req.packed_quantity ===
                                      req.picked_quantity
                                    }
                                    variant="outlined"
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      handleRemoveProduct(
                                        req.id,
                                        req.picked_quantity
                                      )
                                    }
                                  >
                                    <IoMdRemoveCircle />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      {!loading ? (
                        <div className="notfound_img_div">
                          <div className="notfound_img"></div>
                        </div>
                      ) : (
                        <div className="notfound_img_div">
                          <div className="loadingfound_img"></div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DispatchRequirementDetails;
