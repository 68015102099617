import React, { useEffect, useState } from 'react'
import { useScreenWidth } from '../../../useScreenWidth';
import axios from 'axios';
import { apiKey, fetch_sale } from '../../../Api';
import toast, { Toaster } from 'react-hot-toast';
import { format, parseISO } from "date-fns";

function CustomerOrderProducts({ id }) {
    const isWideScreen = useScreenWidth();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(0);
    const [searchquery, setSearchQuery] = useState("");


    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        setCurrentPage(1);
    };

    const handlePageChange = (e, { activePage }) => {
        setCurrentPage(activePage);
    };

    const handlePageSizeChange = (e) => {
        setPageSize(Number(e.target.value));
        setCurrentPage(1);
    };

    const fetchData = (page = currentPage, query = searchquery) => {
        const data = {
            page: parseInt(page),
            per_page: parseInt(pageSize),
            search: query,
            selected_customers: [id] || null,
        };
        const headers = {
            "Content-Type": "application/json",
            "API-Key": apiKey,
        };
        axios
            .post(fetch_sale, data, { headers })
            .then((response) => {
                // console.log("response.data.data : ",response.data.data)
                setProducts(response.data.data);
                setTotalPages(response.data.num_pages);
                setLoading(false);
            })
            .catch((error) => {
                toast.error(error.response?.data?.message);
                setLoading(false);
            });
    };



    useEffect(() => {
        fetchData();
    }, [
        currentPage,
        pageSize,
        searchquery,
        id
    ]);


     function formatIndianRupee(amount) {
            const formatter = new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 2,
            });
            return formatter.format(amount);
        }

    return (
        <>
            <Toaster position="top-right" />

            <div className="card my-2 p-2">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <input
                            className="form-control rounded"
                            placeholder="Search Products..."
                            style={{ backgroundColor: "white" }}
                            type="search"
                            value={searchquery}
                            onChange={handleSearchChange}
                        />
                    </div>

                    {isWideScreen ? (
                        <div className="responsive-table1 mt-2" style={{ maxHeight: "30vh" }}>
                            <table className="ui table celled " id="c_table">
                                <thead className="table-head">
                                    <tr>
                                        <th style={{ width: "2px" }}>#</th>
                                        <th>Sale Date</th>
                                        <th className="text-center">Bill</th>
                                        <th>Created By</th>
                                        <th className="text-center">Total Products</th>
                                        <th style={{ textAlign: "right" }}>Amount</th>

                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.length > 0 ? (
                                        <>
                                            {products &&
                                                products.map((data, index) => (
                                                    <>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    width: "20px",
                                                                    backgroundColor:
                                                                        data.is_cancelled == 1
                                                                            ? "#ffe8e6"
                                                                            : "transparent",
                                                                }}
                                                            >
                                                                {data.id}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "160px",
                                                                    backgroundColor:
                                                                        data.is_cancelled == 1
                                                                            ? "#ffe8e6"
                                                                            : "transparent",
                                                                }}
                                                            >
                                                                <b>
                                                                    {format(
                                                                        parseISO(data.sale_date),
                                                                        "dd MMMM, yyyy"
                                                                    )}
                                                                </b>
                                                            </td>

                                                            <td
                                                                style={{
                                                                    backgroundColor:
                                                                        data.is_cancelled == 1
                                                                            ? "#ffe8e6"
                                                                            : "transparent",
                                                                }}
                                                            >
                                                                <b className="d-flex justify-content-center">



                                                                    
                                                                       { data.is_skd === 1 ? (
                                                                            <span className="badge bg-warning ms-2">SKD</span>
                                                                        ) : data.is_nkd === 1 ? (
                                                                            <span className="badge bg-success ms-2">NKD</span>
                                                                        ) : null}
                                                               
                                                                </b>
                                                            </td>

                                                            <td
                                                                style={{
                                                                    backgroundColor:
                                                                        data.is_cancelled == 1
                                                                            ? "#ffe8e6"
                                                                            : "transparent",
                                                                }}
                                                            >
                                                                {data.created_by}
                                                            </td>

                                                            <td
                                                                style={{
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        data.is_cancelled == 1
                                                                            ? "#ffe8e6"
                                                                            : "transparent",
                                                                }}
                                                            >
                                                                <span className="tbody_hint">
                                                                    No. of Products:{" "}
                                                                </span>
                                                                {data.total_products} QTY
                                                            </td>
                                                            <td
                                                                style={{
                                                                    color: "green",
                                                                    textAlign: "right",
                                                                    backgroundColor:
                                                                        data.is_cancelled == 1
                                                                            ? "#ffe8e6"
                                                                            : "transparent",
                                                                }}
                                                            >
                                                                <b>
                                                                    {formatIndianRupee(
                                                                        data.total_price -
                                                                        data.discount_amount +
                                                                        data.courier_charge +
                                                                        data.other_charge || "N/A"
                                                                    )}
                                                                </b>
                                                            </td>

                                                           
                                                        </tr>
                                                    </>
                                                ))}
                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                <td colSpan={7}>
                                                    {!loading ? (
                                                        <p className="text-center">No Sales Found</p>
                                                    ) : (
                                                        <div className="notfound_img_div" style={{ height: "20vh" }}>
                                                            <div className="loadingfound_img"></div>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <>
                        </>
                    )}
                </div>
            </div>

        </>
    )
}

export default CustomerOrderProducts
