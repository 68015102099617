import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import {
  apiKey,
  adjustmentHostory,
  adjustmentBarcodes,
  export_adjustment,
  fetch_warehouse,
} from "../../../Api";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import ViewAdjustmentModal from "./ViewAdjustmentModal.js";
import PrintBarcode from "../../Purchase/PrintBarcode";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useScreenWidth } from "../../../useScreenWidth.js";
import CustomLoader from "../../../Components/Loader";
import { Icon, Label, Pagination } from "semantic-ui-react";
import toast, { Toaster } from "react-hot-toast";
import FilterSelectionModal from "../../../Components/FilterModal/FilterSelectionModal.js";
import FilterSelectionModalByWarehouse from "../../../Components/FilterModal/FilterSelectionModalByWarehouse.js";
import * as XLSX from "xlsx";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "500px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Adjustments() {
  const isWideScreen = useScreenWidth();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");

  // Filter
  const [types, setTypes] = useState([
    { value: 1, label: "stock in" },
    { value: 2, label: "stock out" },
  ]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  // Warehouse Filter
  const [selectedWarehouseIds, setSelectedWarehouseIds] = useState(null);

  const navigate = useNavigate();

  const debouncedFetchData = useCallback(
    debounce((page, query) => {
      fetchData(page, query);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setPageSize(25);
    setCurrentPage(1);
    debouncedFetchData(1, query);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchquery, selectedTypes, selectedWarehouseIds]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page,
      per_page: pageSize,
      search: query,
      selectedType: selectedTypes || null,
      selectedWarehouses: selectedWarehouseIds || null,
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };
    axios
      .post(adjustmentHostory, data, { headers })
      .then((response) => {
        // console.log("adjustmentHostory : ",response.data.data)
        setdata(response.data.data);
        setTotalPages(response.data.total_pages);

        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        console.log(error.response);
        setLoading(false);
      });
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState("");
  useEffect(() => {
    fetchWarehouses();
  }, []);

  const fetchWarehouses = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setWarehouses(response.data.warehouses);
        // console.log(response.data)
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  // ========= handle View Adjustment Modal

  const [openAdjustmentModal, setOpenAdjustmentModal] = useState(false);
  const [selectedAdjustmentDetail, setSelectedAdjustmentDetail] = useState();
  const [
    selectedAdjustmentDetailShowAllocation,
    setSelectedAdjustmentDetailShowAllocation,
  ] = useState();

  const handleViewAdjustmentModal = (allDetail, showAllocation) => {
    setSelectedAdjustmentDetail({ ...allDetail, showAllocation });
    setSelectedAdjustmentDetailShowAllocation(showAllocation);
    setOpenAdjustmentModal(true);
  };

  // ======= handle Print Barcode Of Adjustment

  const [openModalBarcode, setOpenModalBarcode] = useState(false);
  const [barcodes, setBarcodes] = useState([]);

  const handlePrintBarcode = (adjustmentId) => {
    try {
      axios
        .get(adjustmentBarcodes, {
          params: {
            adjustment_pk: adjustmentId,
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            setBarcodes(response.data.products);

            const barcodeProducts = response.data.products;

            const barcodeData = barcodeProducts.map((product) => ({
              sku: product.sku,
              series: product.series,
              part_number: product.part_number,
              name: product.name,
              brand: product.brand,
            }));

            // Wrap in an array to maintain consistency
            setBarcodes(barcodeData);
            setOpenModalBarcode(true);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [downloadFileLoading,setDownloadFileLoading] = useState(false);
  const downloadFile = () => {
    // console.log(warehouse);
    setDownloadFileLoading(true);
    const params = {
      warehouse_pk: parseInt(warehouse) || "",
    };
    axios
      .get(export_adjustment, {
        params,
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const allItems = response.data.products;

        // Sort the items by SKU
        allItems.sort((a, b) => (a.sku > b.sku ? 1 : -1));

        // Create a worksheet from the data
        const worksheet = XLSX.utils.json_to_sheet(allItems);

        // Create a workbook and add the worksheet to it
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Adjustment Report");

        // Generate a binary string representation of the workbook
        const wbout = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "binary",
        });

        // Convert the binary string to a Blob object
        const blob = new Blob([s2ab(wbout)], {
          type: "application/octet-stream",
        });

        // Create a link element and trigger a download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Adjustment Report.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
        setDownloadFileLoading(false);
        handleCloseWare();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        console.log(error);
        setDownloadFileLoading(false);
      });
  };

  // Utility function to convert a binary string to an array buffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const [openSelectWare, setOpenSelectWare] = useState(false);

  const handleCloseWare = () => {
    setOpenSelectWare(false);
    setWarehouse("");
  };

  return (
    <>
      {loading && <CustomLoader />}

      <Toaster position="top-right" />

      <ViewAdjustmentModal
        open={openAdjustmentModal}
        setOpenAdjustmentModal={setOpenAdjustmentModal}
        adjustmentPk={selectedAdjustmentDetail?.id}
        selectedAdjustmentDetailShowAllocation={
          selectedAdjustmentDetailShowAllocation
        }
      />

      {/* PrintRackBarcode Modal */}
      <PrintBarcode
        handleCloseBarcode={setOpenModalBarcode}
        openBarcode={openModalBarcode}
        barcodes={barcodes}
      />

      <Dialog
        open={openSelectWare}
        onClose={handleCloseWare}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>Export Adjustment</DialogTitle>

        <DialogContent dividers>
          <Box>
            <FormControl fullWidth size="small">
              <InputLabel>Select Warehouse</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={warehouse}
                label="Select Warehouse"
                onChange={(e) => setWarehouse(e.target.value)}
              >
                <MenuItem value="">--Select--</MenuItem>
                {warehouses &&
                  warehouses.map((data) => (
                    <MenuItem value={data.id}>{data.ware_house}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleCloseWare}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            disabled={!warehouse || downloadFileLoading}
            onClick={downloadFile}
            
            startIcon={downloadFileLoading ? <CircularProgress size={20} /> : null}
          >
            {downloadFileLoading ? "Processing..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Adjustments
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-12 col-lg-4">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-12 mt-md-0 mt-2 col-lg-8"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  onClick={() => setOpenSelectWare(true)}
                // onClick={downloadFile}
                >
                  Export
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/add-stock-adjustment")}
                >
                  Add New
                </Button>
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table className="ui table celled " id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>ID</th>
                      <th>Date</th>
                      <th>
                        <FilterSelectionModalByWarehouse
                          title="Warehouse"
                          selectedWarehouseId={selectedWarehouseIds}
                          onSelect={setSelectedWarehouseIds}
                        />
                      </th>
                      <th>
                        <FilterSelectionModal
                          title="Adjustment Type"
                          options={types}
                          selectedOptions={selectedTypes}
                          onSelect={setSelectedTypes}
                        />
                      </th>
                      <th>Notes</th>
                      <th>Products</th>
                      <th>Created By</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      <>
                        {data &&
                          data.map((data) => (
                            <>
                              <tr>
                                <td style={{ width: "60px" }}>{data.id}</td>

                                <td>
                                  {new Intl.DateTimeFormat("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }).format(new Date(data?.date))}
                                </td>

                                <td>{data.warehouse?.ware_house || "-"}</td>
                                <td style={{ width: "150px" }}>
                                  <Label
                                    style={{ width: "75px" }}
                                    className={`label ${data.action == "stock in"
                                        ? "green_label"
                                        : data.action == "stock out"
                                          ? "red_label"
                                          : "blue_label"
                                      }`}
                                  >
                                    {/* <LabelDetail>Co-worker</LabelDetail> */}
                                    {data.action ? data.action : "-"}
                                  </Label>
                                </td>
                                <td>{data.note ? data.note : "-"}</td>
                                <td>
                                  {data.total_products
                                    ? data.total_products
                                    : "-"}
                                </td>
                                <td>{data.user_name ? data.user_name : "-"}</td>
                                <td
                                  style={{
                                    width: "200px",
                                    textAlign: "center",
                                  }}
                                >
                                  <Button
                                    variant="outlined" disabled={data.action == "stock out"}
                                    size="small"
                                    color="primary"
                                    className="me-2"
                                    onClick={() =>
                                      handleViewAdjustmentModal(data, true)
                                    } // Pass true for Allocate button
                                  >
                                    Allocate
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="success"
                                    onClick={() =>
                                      handleViewAdjustmentModal(data, false)
                                    } // Pass false for View button
                                  >
                                    View
                                  </Button>

                                  <Button
                                    style={{ marginLeft: "5px" }}
                                    variant="outlined"
                                    disabled={
                                      data.action === "stock out" ||
                                      data.print_status === 0
                                    }
                                    size="small"
                                    color="error"
                                    onClick={() => handlePrintBarcode(data.id)}
                                  >
                                    Print
                                  </Button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan="7">
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {data.length > 0 ? (
                  <>
                    {data &&
                      data.map((data) => (
                        <>
                          <div
                            className="product-card"
                            id="product_card"
                          // onClick={() => handleViewAdjustmentModal(data)}
                          >
                            <div className="py-1 px-2 w-100">
                              <h5 className="product-card-sku">#{data.id}</h5>
                              <h5 className="product-card-name">
                                {data.warehouse?.ware_house || "-"}
                              </h5>
                              <h5 className="warehouse-card-address">
                                Total Products:{" "}
                                <b style={{ color: "black" }}>
                                  {data.total_products
                                    ? data.total_products
                                    : "-"}
                                </b>
                              </h5>

                              <div className="mt-2 d-flex align-items-center gap-2 justify-content-between w-100">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary" disabled={data.action == "stock out"}
                                  className=" w-50 d-block"
                                  onClick={() =>
                                    handleViewAdjustmentModal(data, true)
                                  } // Pass true for Allocate button
                                >
                                  Allocate
                                </Button>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  className=" w-50 d-block"
                                  color="success"
                                  onClick={() =>
                                    handleViewAdjustmentModal(data, false)
                                  } // Pass false for View button
                                >
                                  View
                                </Button>
                              </div>
                            </div>

                            {/* <h5 className="my-1 warehouse-card-label"> */}
                            <Label
                              className="label"
                              style={{
                                width: "85px",
                                textAlign: "center",
                                position: "absolute",
                                right: "12px",
                                top: "12px",
                              }}
                              color={
                                data.action == "stock in" ? "green" : "red"
                              }
                            >
                              {/* <LabelDetail>Co-worker</LabelDetail> */}
                              {data.action ? data.action : "-"}
                            </Label>
                            {/* </h5> */}
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "2px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Adjustments;
